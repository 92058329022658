export const endpoints = {
  // Default endpoints
  companyConfig: `/CompanyConfig`,
  basicCompanyConfig: `/BasicCompanyConfig`,
  companyData: `/CompanyData`,
  validConnection: `/ValidConnection`,
  getLanguages: `/GetLanguages`,
  getCoutries: `/GetCountries`,
  regimenesFiscales: `/RegimenesFiscales`,
  negocios: `/Negocios`,
  paymentFormat: `/MetodosPago`,
  getSCSSAttributes: `/getSCSSAttributes`,

  // Groups, families and articles
  superGroups: `/SuperGroups`,
  families: `/Families`,
  articles: `/Articles`,
  article: `/Article`,
  getNewArticles: '/getNewArticles',
  obtenerLoMasPedidoPorTodosLosClientes: '/ObtenerLoMasPedidoPorTodosLosClientes',

  // Auth endpoints
  login: `/Login`,
  validateToken: `/ValidateToken`,
  refreshToken: `/RefreshToken`,
  getTokenClient: `/GetTokenClient`,
  registerCompany: `/RegisterCompany`,
  registerUser: `/RegisterClient`,
  getUser: `/GetUser`,
  setPassword: `/SetPassword`,
  editProfile: `/EditProfile`,
  getClients: `/GetClients`,
  getClientCommercial: `/GetClientCommercial`,
  sendPasswordEmail: `/SendPasswordEmail`,
  getNumberOfArticles: `/GetNumberOfArticles`,
  getVendors: `/GetVendors`,

  // Auth Directions
  newDirection: `/NewDirection`,
  getUserDirections: `/GetUserDirections`,
  deleteUserDirection: `/DeleteUserDirection`,
  updateDirection: `/UpdateDirection`,
  updatePostalDirection: `/UpdatePostalDirection`,
  renewPassword: `/RenewPassword`,
  validatePasswordUrl: `/ValidatePasswordUrl`,
  obtenerPerfilEmpresa: `/ObtenerPerfilEmpresa`,
  bloquearCliente: `/BloquearCliente`,

  // Auth Order
  obtenerPedidosSinServir: `/ObtenerPedidosSinServir`,
  modificarPedidoSinServir: `/ModificarPedidoSinServir`,
  getUserOrders: `/GetUserOrders`,
  getUserOrdersByDateRange: `/GetUserOrdersByDateRange`,
  getMostRequestedArticles: `/GetMostRequestedArticles`,
  getMostRequestedArticlesBySuperGroup: `/GetMostRequestedArticlesBySuperGroup`,

  // Shopping car
  addPendingArticle: `/AddPendingArticle`,
  addPendingArticles: `/AddPendingArticles`,
  deletePendingArticle: `/DeletePendingArticle`,
  DeletePendingArticles: `/DeletePendingArticles`,
  SetListArticlesQuantity: `/SetListArticlesQuantity`,
  deleteAllArticles: `/DeleteAllArticles`,
  getPendingArticles: `/GetPendingArticles`,
  getPendingArticleQuantity: `/GetPendingArticleQuantity`,
  setQuantity: `/SetQuantity`,
  setLumps: `/SetLumps`,
  setCutType: `/SetCutType`,
  setObservations: `/setObservations`,
  makeOrder: `/Order`,
  pendingOrder: `/PendingOrder`,
  minOrderPrice: `/MinOrderPrice`,
  deliveryDate: `/DeliveryDate`,
  getDeliveryTime: `/GetDeliveryTime`,
  articuloRegalo: `/ArticuloRegalo`,

  // TPV
  TpvParams: `/TpvParams`,
  ValidTpv: `/ValidTpv`,
  pagoPaycomet: `/PagoPaycomet`,

  // Exceptions
  OnlyRegisterCompanies: `/OnlyRegisterCompanies`,
  PermitRegisterCompanies: `/PermitRegisterCompanies`,
  useRoutes: `/UseRoutes`,
  typeCompany: `/TypeCompany`,
  obtenerFacturaDesdePedidoPdf: `/ObtenerFacturaDesdePedidoPdf`,

  sendContactMail: `/SendContactMail`,

  // Portal de clientes
  obtenerAlbaranDePedidoPdf: `/ObtenerAlbaranDePedidoPdf`,
  obtenerAlbaranDePedidoBits: `/ObtenerAlbaranDePedidoBits`,
  obtenerAlbaranesCliente: `/ObtenerAlbaranesCliente`,
  obtenerAlbaranesNoFacturados: `/ObtenerAlbaranesNoFacturados`,
  obtenerAlbaranCliente: `/ObtenerAlbaranCliente`,
  obtenerAlbaranClientePdf: `/ObtenerAlbaranClientePdf`,
  enviarAlbaranMail: `/EnviarAlbaranMail`,
  obtenerFacturasCliente: `/ObtenerFacturasCliente`,
  obtenerFacturasNoPagadasCliente: `/ObtenerFacturasNoPagadasCliente`,
  obtenerFacturaEnPdf: `/ObtenerFacturaEnPdf`,
  obtenerFacturaCliente: `/ObtenerFacturaCliente`,
  enviarFacturaMail: `/EnviarFacturaMail`,
  obtenerPendienteDeCobro: `/ObtenerPendienteDeCobro`,

  // Estadisticas
  obtenerLosArticulosMasPedidosPorKilos: `/ObtenerLosArticulosMasPedidosPorKilos`,
  obtenerVentasPorClientes: `/ObtenerVentasPorClientes`,
};

