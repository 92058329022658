/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import {enableProdMode} from '@angular/core';

enableProdMode();
function bootstrap() {
 platformBrowserDynamic().bootstrapModule(AppModule, {
  useJit: true,
  preserveWhitespaces: true
})
  .catch(err => console.log(err));
};


 if (document.readyState === 'complete') {
 bootstrap();
 } else {
 document.addEventListener('DOMContentLoaded', bootstrap);
 }

