<div *ngIf="showOrder && articles.length > 0">
  <h3 class="text-info font-weight-bold container-fluid mt-3" *ngIf="showReturn && processOrderNav"
      (click)="showOrder = false"><i class="fas fa-arrow-left"></i> VOLVER</h3>
  <div *ngIf="countries != undefined; else cargando">
  <app-order [userInfo]="userInfo" [articles]="articles" [totalIvaPrice]="totalIvaPrice"
     [companyConf]="companyConf" [totalTransport]="totalTransport"
     (finishOrder)="finishOrder()" [countries]="countries" [minPrice]="minPrice"
     (returnShoppingCar)="reloadData()" (getUserData)="getUserDataFn()"
     (setHomeDelivery)="setHomeDelivery($event)"
     [superGroups]="superGroups" [priceRoute]="priceRoute"
     [recalcularPrecioEntrega]="recalcularPrecioEntrega" [calculateIvaPrices]="calculateIvaPrices"
     [getDifferencePrice]="getDifferencePrice " [missingPrice]="missingPrice"
     [selectedOrderSuperGroup]="selectedOrderSuperGroup" [newUser]="newUser" [homeDelivery]="homeDelivery"
     [codigoPostalValido]="codigoPostalValido" [codigoPostal]="codigoPostal"
     [userDiscount]="userDiscount"></app-order>
  </div>

  <ng-template #cargando>
  <div class="align-items-center d-flex justify-content-center container-load">
  <div>
    <div class="w-100 d-flex justify-content-center mb-3">
    <img src="../../../../../../assets/loading/loading.gif"  alt="Cargando icono" title="Icono cargando datos" style="height: 150px !important; width: auto !important;"/>
    </div>
    <div class="text-center">
    <h3>Estamos cargando todos los datos</h3>
    <p style="font-size: 14px">Espere un momento</p>
    </div>
  </div>
  </div>
  </ng-template>
</div>

<div *ngIf="!showOrder || articles.length == 0">
  <div class="container-fluid">
  <div class="row flex-column-reverse flex-md-row mb-5" [ngClass]="{'mt-5': innerWidth > 766}">
  <div class="col-md-9">
    <div class="row">
    <div class="col-12">
    <div *ngIf="articles.length === 0 && someArticles"
       class="align-items-center d-flex justify-content-center container-load">
      <div>
      <div class="w-100 d-flex justify-content-center mb-3">
      <img src="../../../../../../assets/loading/loading.gif"  alt="Cargando icono" title="Icono cargando datos" style="height: 150px !important; width: auto !important;"/>
      </div>
      <div class="text-center">
      <h3>Estamos cargando todos los datos</h3>
      <p style="font-size: 14px">Espere un momento</p>
      </div>
      </div>
    </div>

    <div *ngIf="!someArticles">
      <div class="w-100 bg-info-light mb-3">
      <h5 class="ml-3 font-size-title">No hay articulos en este momento en su carrito.</h5>
      </div>
    </div>

    <div class="container-fluid" *ngIf="innerWidth > 1000">
      <div class="row border-bottom mb-3">
      <div class="col-md-6 font-size-title pb-2">
      Producto
      </div>

      <div class="col-md-2 font-size-title pb-2">
      Precio
      </div>

      <div class="col-md-2 font-size-title pb-2">
      Cant.
      </div>

      <div class="col-md-1 font-size-title pb-2">
      Subtotal
      </div>
      </div>
    </div>

    <div *ngFor="let article of articles | paginate: {itemsPerPage: numberArticlesPage, currentPage: pageArticles}; let i = index"
       class="container-fluid pb-2 mb-2 border-bottom">
      <div class="row">
        <div [ngClass]="{'col-6': innerWidth > 1000, 'col-11': innerWidth <= 1000}">
        <div class="d-flex">
          <div *ngIf="(article.articleImgUrl || article.productImgUrl)">
          <img *ngIf="article.articleImgUrl; else productImg"
           src="./assets/fotos/articulos/{{article.articleImgUrl}}" alt="{{article.name}}"
           height="150px" width="auto"/>
          <ng-template #productImg>
                      <img *ngIf="article.productImgUrl"
                               src="./assets/fotos/productos/{{article.productImgUrl}}" alt="{{article.name}}"
                               height="150px" width="auto"/>
                        </ng-template>
        </div>

        <div class="ml-2 d-flex align-items-center">
            <div>
              <div class="font-size-title mb-2">
                {{article.name}}
              </div>

              <div class="mr-4 mt-0" *ngIf="article.dropDownGauge && article.gaugeName">
                Tamaño: <b>{{article.gaugeName}}</b>
              </div>

              <div class="mr-4 mt-0" *ngIf="article.cutName">
                <mat-form-field class="w-100">
                  <mat-label>Tipo de corte o presentación</mat-label>
                  <mat-select class="w-100"
                              (ngModelChange)="setCutType(article, $event)" name="nombre" [(ngModel)]="article.cutType">
                    <mat-option *ngFor="let cut of article.dropDownCuts"
                                [disabled]="existSameArticle(article.id, cut.id, article.line)"
                                [value]="cut.id">{{cut.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="d-flex align-items-center" *ngIf="innerWidth <= 1000">
                <div class="font-size-title mb-2">
                  {{article.priceIva  | currency : 'EUR'}}
                  <span *ngIf="companyConf.companyException != 'ENJA'">
                    <span *ngIf="article.format === '1'" class="format-width">/Kg</span>
                    <span *ngIf="article.format !== '1'" class="format-width">/Ud</span>
                  </span>
                </div>
              </div>

              <div *ngIf="article.observations && companyConf.companyException != 'ENJA'">
                <button type="button" class="btn btn-primary text-light"
                        (click)="openObservation($event, article.id, article.line, setObservation)">
                  <i class="fas fa-pen" ></i>
                </button>
                Observaciones:<b> {{article.observations}}</b>
              </div>

              <div *ngIf="!article.observations && companyConf.companyException != 'ENJA'">
                <button type="button" class="btn btn-primary text-light"
                        (click)="openObservation($event, article.id, article.line, setObservation)">
                  <i class="fas fa-pen"></i> Observaciones
                </button>
              </div>

              <ng-template #setObservation let-modal class="modal">
                <div class="modal-body">
                  <div class="w-100 d-flex justify-content-end">
                    <div class="text-danger" (click)="modal.close()">
                      <i class="fas fa-times-circle fa-2x"></i>
                    </div>
                  </div>
                  <form [formGroup]="observationsForm" novalidate>
                    <div class="form-group">
                      <label class="font-weight-bold w-100">Observaciones<br/>
                        <span class="font-weight-light">***No todas las observaciones se podrán llevar a cabo**</span>
                        <textarea class="form-control" formControlName="observations"
                                  [placeholder]="companyConf.observationsText"></textarea>
                      </label>
                      <div *ngIf="observationsForm.controls.observations.touched &&
                            !observationsForm.valid "
                           class="error">Tamaño máximo de 60 caracteres.
                      </div>
                    </div>
                  </form>

                  <div class="d-flex justify-content-center">
                    <button type="submit" class="btn btn-danger btn-modal cancel-modal"
                            (click)="modal.close()">CANCELAR</button>
                    <button type="submit" class="btn btn-success btn-modal" [disabled]="!observationsForm.valid"
                            (click)="setArticleObservations(modal, article.id, article.line)">ACEPTAR</button>
                  </div>
                </div>
              </ng-template>


              <div *ngIf="innerWidth <= 1000" class="mt-3">
                <!-- Cantidad en unidades -->
                <div *ngIf="article.format !== '1'">
                  <div *ngIf="companyConf.companyException == 'ENJA'; else cantidadNormal">
                    <div class="d-flex">
                      <div class="btn btn-light-gray text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                           (click)="stopPropagation($event); restarCantidad(article); setQuantity(article)"> - </div>
                      <div class="form-group" style="width: 5em;">
                        <span class="w-100 form-control text-center"> {{article.quantity}} </span>
                      </div>
                      <div class="btn btn-light-gray text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                           (click)="stopPropagation($event); aumentarCantidad(article); setQuantity(article)"> + </div>
                    </div>
                  </div>

                  <ng-template #cantidadNormal>
                    <div *ngIf="!companyConf.selectQuantity; else elseSelect">
                      <mat-form-field   style="max-width: 100px !important;">
                        <mat-label>Cantidad</mat-label>
                        <input type="number" class="mr-1"
                               matInput
                               name="nombre" [(ngModel)]="article.quantity"
                               (click)="stopPropagation($event)"
                               (ngModelChange)="setQuantity(article)">
                      </mat-form-field>
                    </div>

                    <ng-template #elseSelect>
                      <select class="quantity-select browser-default custom-select p-0 mb-0 mr-1"
                              name="nombre" [(ngModel)]="article.quantity"
                              (click)="stopPropagation($event)"
                              (change)="setQuantity(article)">
                        <option [value]="unit" *ngFor="let unit of selectUnits">
                          &nbsp;{{unit}}
                        </option>
                      </select>
                    </ng-template>
                  </ng-template>
                </div>

                <!-- Cantidad en Kilos -->
                <div *ngIf="article.format === '1'">
                  <div *ngIf="companyConf.companyException == 'ENJA'; else cantidadNormal">
                    <div class="d-flex">
                      <div class="btn btn-light-gray text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                           (click)="stopPropagation($event); restarCantidad(article); setQuantity(article)"> - </div>
                      <div class="form-group ml-2 mr-2" style="width: 3.75em;">
                        <span class="w-100 form-control text-center"> {{article.quantity}} </span>
                      </div>
                      <div class="btn btn-light-gray text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                           (click)="stopPropagation($event); aumentarCantidad(article); setQuantity(article)"> + </div>

                    </div>
                  </div>

                  <ng-template #cantidadNormal>
                    <div *ngIf="!companyConf.selectQuantity; else elseSelect">
                      <mat-form-field   style="max-width: 100px !important;">
                        <mat-label>Cantidad</mat-label>
                        <input type="number" class="mr-1"
                               matInput
                               name="nombre" [(ngModel)]="article.quantity"
                               (click)="stopPropagation($event)"
                               (ngModelChange)="setQuantity(article)">
                      </mat-form-field>
                    </div>

                    <ng-template #elseSelect>
                      <select class="quantity-select browser-default custom-select p-0 mb-0 mr-1"
                              name="nombre" [(ngModel)]="article.quantity"
                              (click)="stopPropagation($event)"
                              (change)="setQuantity(article)">
                        <option [value]="kg" *ngFor="let kg of selectKgs">
                          &nbsp;{{kg | number : '1.2-2'}}
                        </option>
                      </select>
                    </ng-template>
                  </ng-template>
                </div>

                <div *ngIf="article.lumps > 0 && companyConf.companyType === 'P'" >
                  <div class="d-flex justify-content-end mt-2 mb-2">
                    <div class="btn btn-dark text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                         (click)="stopPropagation($event); addValue(article)"> + </div>
                    <div class="form-group ml-2 mr-2" style="width: 3.75em;">
                      <span class="w-100 form-control text-center"> {{article.lumps}} </span>
                    </div>
                    <div class="btn btn-dark text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                         (click)="stopPropagation($event); subtractValue(article)"> - </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>

    <div class="col-md-2"  *ngIf="innerWidth > 1000">
      <div class="d-flex align-items-center">
        <div class="font-size-title mt-2">
          {{article.priceIva  | currency : 'EUR'}}
          <span *ngIf="companyConf.companyException != 'ENJA'">
            <span *ngIf="article.format === '1'" class="format-width">/Kg</span>
            <span *ngIf="article.format !== '1'" class="format-width">/Ud</span>
          </span>
        </div>
      </div>
    </div>

    <div class="col-md-2"  *ngIf="innerWidth > 1000">
      <!-- Cantidad en unidades -->
      <div *ngIf="article.format !== '1'">
        <div *ngIf="companyConf.companyException == 'ENJA'; else cantidadNormal">
          <div class="d-flex">
            <div class="btn btn-light-gray text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                 (click)="stopPropagation($event); restarCantidad(article); setQuantity(article)"> - </div>
            <div class="form-group" style="width: 5em;">
              <span class="w-100 form-control text-center"> {{article.quantity}} </span>
            </div>
            <div class="btn btn-light-gray text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                 (click)="stopPropagation($event); aumentarCantidad(article); setQuantity(article)"> + </div>
          </div>
        </div>

        <ng-template #cantidadNormal>
          <div *ngIf="!companyConf.selectQuantity; else elseSelect">
            <mat-form-field   style="max-width: 100px !important;">
              <mat-label>Cantidad</mat-label>
              <input type="number" class="mr-1"
                     matInput
                     name="nombre" [(ngModel)]="article.quantity"
                     (click)="stopPropagation($event)"
                     (ngModelChange)="setQuantity(article)">
            </mat-form-field>
          </div>

          <ng-template #elseSelect>
            <select class="quantity-select browser-default custom-select p-0 mb-0 mr-1"
                    name="nombre" [(ngModel)]="article.quantity"
                    (click)="stopPropagation($event)"
                    (change)="setQuantity(article)">
              <option [value]="unit" *ngFor="let unit of selectUnits">
                &nbsp;{{unit}}
              </option>
            </select>
          </ng-template>
        </ng-template>
      </div>

      <!-- Cantidad en Kilos -->
      <div *ngIf="article.format === '1'">
        <div *ngIf="companyConf.companyException == 'ENJA'; else cantidadNormal">
          <div class="d-flex">
            <div class="btn btn-light-gray text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                 (click)="stopPropagation($event); restarCantidad(article); setQuantity(article)"> - </div>
            <div class="form-group ml-2 mr-2" style="width: 3.75em;">
              <span class="w-100 form-control text-center"> {{article.quantity}} </span>
            </div>
            <div class="btn btn-light-gray text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                 (click)="stopPropagation($event); aumentarCantidad(article); setQuantity(article)"> + </div>

          </div>
        </div>

        <ng-template #cantidadNormal>
          <div *ngIf="!companyConf.selectQuantity; else elseSelect">
            <mat-form-field   style="max-width: 100px !important;">
              <mat-label>Cantidad</mat-label>
              <input type="number" class="mr-1"
                     matInput
                     name="nombre" [(ngModel)]="article.quantity"
                     (click)="stopPropagation($event)"
                     (ngModelChange)="setQuantity(article)">
            </mat-form-field>
          </div>

          <ng-template #elseSelect>
            <select class="quantity-select browser-default custom-select p-0 mb-0 mr-1"
                    name="nombre" [(ngModel)]="article.quantity"
                    (click)="stopPropagation($event)"
                    (change)="setQuantity(article)">
              <option [value]="kg" *ngFor="let kg of selectKgs">
                &nbsp;{{kg | number : '1.2-2'}}
              </option>
            </select>
          </ng-template>
        </ng-template>
      </div>

      <div *ngIf="article.lumps > 0 && companyConf.companyType === 'P'" >
        <div class="d-flex justify-content-end mt-2 mb-2">
          <div class="btn btn-dark text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
               (click)="stopPropagation($event); addValue(article)"> + </div>
          <div class="form-group ml-2 mr-2" style="width: 3.75em;">
            <span class="w-100 form-control text-center"> {{article.lumps}} </span>
          </div>
          <div class="btn btn-dark text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
               (click)="stopPropagation($event); subtractValue(article)"> - </div>
        </div>
      </div>
    </div>

    <div class="col-md-1"  *ngIf="innerWidth > 1000">
      <div class="d-flex align-items-center mt-2">
        <div class="font-size-title" [ngClass]="{'mr-4': innerWidth > 800}">
          {{article.totalPriceIva | currency: 'EUR'}}
        </div>
      </div>
    </div>

    <div class="col-1" [ngClass]="{'height-150px': innerWidth > 800}" >
      <div class="d-flex align-items-center justify-content-end" >
        <button type="button" class="btn"
                (click)="removeArticle(article.id, article.line, article.cutType)">
          <i class="fa-solid fa-circle-xmark"></i>
        </button>

      </div>
    </div>
  </div>
</div>

            <div *ngIf="articles" id="pagination-container">
              <b>
                <pagination-controls class="pagination justify-content-end"
                                     (pageChange)="pageChanged($event)"></pagination-controls>
              </b>
            </div>


          </div>
        </div>
      </div>

      <div class="col-md-3 bg-light-gray pb-3 pt-5 pl-4 pr-4 mb-5">
        <div class="text-danger font-size-title">
          Total: {{articles.length}} producto<span *ngIf="articles.length != 1">s</span>
        </div>

        <div class="text-dark mt-4 pb-3 font-size-title">
          <div class="row">
            <div class="col-8">
              Subtotal
            </div>
            <div class="col-4 text-right">
              {{(totalIvaPrice| currency: 'EUR')}}
            </div>
          </div>
        </div>

        <div class="text-dark mt-3 font-size-title">
          <div class="row">
            <div class="col-5">
              Envío
            </div>
            <div class="col-7 text-right">
              <div  *ngIf="(codigoPostal != undefined && codigoPostalValido) || storePickup; else cpNoValido">

                <div *ngIf="storePickup || ((totalIvaPrice >= minPrice || minPrice == undefined) && (totalTransport == 0 || totalTransport == undefined))">
                  <div *ngIf="homeDelivery; else pickUpStore">
                    GRATUITO
                  </div>
                  <ng-template #pickUpStore>
                    RECOGIDA EN TIENDA
                  </ng-template>
                </div>

                <div *ngIf="totalIvaPrice < minPrice && (totalTransport == 0 || totalTransport == undefined) && !storePickup">
                  <div *ngIf="missingPrice">
                    FALTAN <span class="text-danger">{{(missingPrice | currency: 'EUR')}}</span>
                  </div>
                </div>

                <div *ngIf="priceRoute != undefined">
                  <div *ngIf="!(priceRoute.minKg == 0 && priceRoute.deliveryPrice == 0 && priceRoute.deliveryKg == 0) && totalTransport > 0">
                    <div (click)="openModal(TRANSPORT)">
                      {{totalTransport  | currency: 'EUR'}}

                      <div *ngIf="!validPriceRoute">
                        Peso mínimo: {{priceRoute.minKg}} Kg
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ng-template #cpNoValido>
                <div class="text-danger" *ngIf="codigoPostal == undefined; else noHayEntregas">
                  Indicar los datos de entrega
                </div>

                <ng-template #noHayEntregas>
                  <div class="text-danger">
                    No hay entregas
                  </div>
                </ng-template>
              </ng-template>

            </div>
          </div>
        </div>

        <div class="text-dark mt-3 font-size-title" *ngIf="userDiscount != 0">
          <div class="row">
            <div class="col-5">
              Descuento
            </div>
            <div class="col-7 text-right">
              {{userDiscount}} %
            </div>
          </div>
        </div>

        <div class="text-dark mt-3 font-size-title border-top border-light pt-4 mt-4">
          <div class="row">
            <div class="col-8">
              Total (impuestos inc.)
            </div>
            <div class="col-4 text-right">
              {{(totalIvaPrice + totalTransport | currency: 'EUR')}}
            </div>
          </div>
        </div>

        <div class="mt-5 mb-5 w-100">
          <p class="font-weight-bold text-info mb-4" style="font-size: 1.25em">Datos de la entrega</p>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label class="text-dark">Introduzca su código postal</mat-label>
            <input type="text" class="mr-1" [(ngModel)]="codigoPostal"
                   (ngModelChange)="quitarRecogidaTienda(); recalcularPrecioEntrega()" matInput>
          </mat-form-field>

          <div *ngIf="companyConf.storePickup">
            <button type="button"  class="btn w-100"
                    [ngClass]="{'btn-dark': !storePickup, 'btn-success': storePickup}"
                    (click)="recogidaEnTienda()">
              <span *ngIf="!storePickup">Recoger en tienda</span>
              <span  *ngIf="storePickup"><i class="fa-solid fa-circle-check"></i> Recogida en tienda</span>
            </button>
          </div>
        </div>

        <div class="mt-4">
          <button type="button" class="btn btn-primary text-light w-100 mb-3"
                  *ngIf="priceRoute.minKg == 0 && priceRoute.deliveryPrice == 0 && priceRoute.deliveryKg == 0 && !storePickup; else elseBtn"
                  [disabled]="articles == null || totalIvaPrice < minPrice || !anyArticleNull || articles.length == 0"
                  (click)="scrollTop(); showOrder = true; processOrderNav = true;">
            FINALIZAR COMPRA
          </button>

          <ng-template #elseBtn>
            <button type="button" class="btn btn-primary text-light w-100 mb-3"
                    [disabled]="articles == null || !anyArticleNull || articles.length == 0 || !validPriceRoute || !(this.storePickup || this.codigoPostalValido)"
                    (click)="scrollTop(); showOrder = true;  processOrderNav = true;">
              FINALIZAR COMPRA
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Info transport -->
<ng-template #TRANSPORT let-modal class="modal">
  <div class="modal-body">
    <div class="container">
      <div class="d-flex mb-2">
        <div class="font-weight-bold mr-4" style="width: 90%;">
          <h1 class="text-info">INFORMACIÓN</h1>
        </div>
        <div class="d-flex justify-content-end"
             style="width: 10%">
          <a class="text-danger" (click)="modal.close()">
            <i class="fas fa-times-circle fa-2x"></i>
          </a>
        </div>
      </div>

      <div class="w-100 font-weight-bold text-center mb-2">
        <h3 class="text-dark font-weight-bold" *ngIf="priceRoute.deliveryPrice != 0 &&
                                                      priceRoute.deliveryKg !=  0">
          El precio transporte es de
          {{(priceRoute.deliveryPrice  | currency: 'EUR')}}
          cada {{priceRoute.deliveryKg}}Kg.<br/>
          <span *ngIf="priceRoute.fixedPrice">Además el envío tiene unos cargos fijos de {{priceRoute.fixedPrice}}€</span>
        </h3>

        <h3 class="text-dark font-weight-bold" *ngIf="priceRoute.deliveryPrice != 0 &&
                                                      priceRoute.minKg !=  0">
          Por compras inferiores a {{priceRoute.minKg}}Kg no se realizan envíos. <br/>
          El precio de transporte es de
          {{(priceRoute.deliveryPrice  | currency: 'EUR')}}.<br/>
          <span *ngIf="priceRoute.fixedPrice">Además tiene unos cargos fijos de {{priceRoute.fixedPrice}}€</span>
        </h3>

        <h3 class="text-dark font-weight-bold" *ngIf="priceRoute.deliveryPrice != 0 ">
          El precio de transporte es de
          {{(priceRoute.deliveryPrice  | currency: 'EUR')}}.<br/>

          Si supera los {{this.minPrice | currency: 'EUR'}} ¡La entrega es gratuita!
        </h3>
      </div>

      <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-info  btn-modal" (click)="modal.close()" >
          ACEPTAR
        </button>
      </div>
    </div>
  </div>
</ng-template>

