<div *ngIf="articles.length > 0; else loading">
  <div class="container-fluid mt-3">
  <div class="row">
  <div class="col-12">
    <div class="navigation_page mb-3 d-flex align-items-center">
    <i class="fas fa-home mr-1"></i>
    <div class="navigation-pipe mr-1" *ngIf="!offers"> » </div>
    <div
    *ngIf="superGroup  && !searchWindow && !offers && superGroupId !== 'none' && superGroup.groups.length > 1;">
    <a *ngIf="groupId != '*' && superGroup.id !== '' && superGroup.groups.length > 1" itemprop="url"
      (click)="redirectToGrupo(superGroup.name, superGroupId, '*')">
      <h1 itemprop="title">{{superGroup.name}}</h1>
    </a>

    <h1 itemprop="title" *ngIf="groupId == '*' || superGroup.groups.length == 1">{{superGroup.name}}</h1>
    <span *ngIf="groupId != '*'  && superGroup.groups.length > 1" class="navigation-pipe mr-1"> » </span>
    </div>

    <div *ngIf="(groupId != '*' && !searchWindow && !offers) || superGroupId == 'none'; else elseMenu1">
    <h1 itemprop="title">{{groupName}}</h1>
    </div>

    <div *ngIf="superGroup && !searchWindow && !offers">
    <div *ngIf="superGroupId !== 'none' && groupId === '*' && superGroup.groups.length == 1; else elseMenu1">
      <h1 itemprop="title">{{superGroup.groups[0].name}}</h1>
    </div>
    </div>

    <ng-template #elseMenu1>
    <h1 class="navigation-pipe mr-1" *ngIf="offers"> » PROMOCIONES</h1>
    <h1 class="navigation-pipe mr-1" *ngIf="searchWindow"> BUSCAR</h1>
    </ng-template>
    </div>
  </div>
  </div>

  <div class="sticky-top w-100 bg-white border-bottom" [hidden]="innerWidth > 990 && companyConf.companyException != 'ENJA'">
  <app-carousel-groups [superGroups]="superGroups"></app-carousel-groups>
  </div>

  <div class="row">
  <div class="col-md-3 col-12 mt-3"
     *ngIf="companyConf.companyException != 'ENJA'"
     id="index">
    <!-- FILTRO ORDENADOR -->
    <div class="filter-computer">
    <div class="card">
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
      <div class="card-title text-dark">FILTRAR</div>
      </li>

      <li class="list-group-item" *ngIf="!searchWindow && !offers && familiesOccult">
      <div class="d-flex" (click)="showFamiliesFn()">
      <div style="font-size: 1.25em">PRODUCTOS</div>
      <div class="drop-down-icon d-flex justify-content-end">
        <i class="fas fa-caret-down"></i>
      </div>
      </div>
      <div class="listContent" *ngIf="showFamilies">
      <ul class="list-group list-group-flush container-family">
        <div *ngFor="let family of families">
        <div class="content-filter">
        <label>
          <input type="checkbox" [value]="family.id" [defaultChecked]="familySelected(family.id)"
          (change)="filterFamilyFn($event)">
          {{family.name}}
        </label>
        </div>
        </div>
      </ul>
      </div>
      </li>

      <li class="list-group-item">
      <div class="d-flex" (click)="showFormatsFn()">
      <div style="font-size: 1.25em">FORMATO</div>
      <div class="drop-down-icon d-flex justify-content-end">
        <i class="fas fa-caret-down"></i>
      </div>
      </div>
      <div class="listContent" *ngIf="showFormats">
      <ul class="list-group list-group-flush container-family">
        <div class="content-filter" *ngFor="let format of formatNames">
        <label>
        <input type="checkbox" [value]="format" [defaultChecked]="formatSelected(format)"
          (change)="filterFormatFn($event)">
        {{format}}
        </label>
        </div>
      </ul>
      </div>
      </li>

      <li class="list-group-item" *ngIf="!offers && showOffersFilter">
      <div class="d-flex">
      <div style="font-size: 1.25em">
        <label>
        <input type="checkbox" (change)="filterOfferFn()">
        PROMOCIONES
        </label>
      </div>
      </div>
      </li>
    </ul>
    </div>
    </div>

  </div>
  <div class="col-12 mt-3" [ngClass]="{'col-md-9': companyConf.companyException != 'ENJA'}">
    <!-- FILTRO MOBIL-->
    <div class="card filter-mobil w-100">
    <ul class="list-group list-group-flush">
    <li class="d-flex align-items-center" id="filter-mobil-title" (click)="showFilterMobilFn()">
      <i class="fas fa-filter" style="margin: 10px 15px auto 10px"></i>
      <div class="card-title text-dark font-weight-bold" id="card-title-mobil">FILTRAR</div>
      <div class="drop-down-icon d-flex justify-content-end">
      <i class="fas fa-caret-down" style="margin: 8px 15px 8px auto"></i>
      </div>
    </li>

    <div *ngIf="showFilterMobil">
      <li class="list-group-item" *ngIf="!searchWindow && !offers">
      <div class="d-flex" (click)="showFamiliesFn()">
        <div style="font-size: 1.25em">PRODUCTOS</div>
          <div class="drop-down-icon d-flex justify-content-end">
                    <i class="fas fa-caret-down"></i>
                  </div>
                </div>
                <div class="listContent" *ngIf="showFamilies">
                  <ul class="list-group list-group-flush">
                    <div *ngFor="let family of inputFamilies">
                      <div class="content-filter" *ngIf="family.group === this.groupId">
                        <label>
                          <input class="form-check-input" type="checkbox" [value]="family.id"
                            [defaultChecked]="familySelected(family.id)" (change)="filterFamilyFn($event)">
                          {{family.name}}
                        </label>
                      </div>
                    </div>
                  </ul>
                </div>
              </li>

              <li class="list-group-item">
                <div class="d-flex" (click)="showFormatsFn()">
                  <div style="font-size: 1.25em">FORMATO</div>
                  <div class="drop-down-icon d-flex justify-content-end">
                    <i class="fas fa-caret-down"></i>
                  </div>
                </div>
                <div class="listContent" *ngIf="showFormats">
                  <ul class="list-group list-group-flush">
                    <li class="content-filter" *ngFor="let format of formatNames">
                      <label>
                        <input class="form-check-input" type="checkbox" [value]="format"
                          [defaultChecked]="formatSelected(format)" (change)="filterFormatFn($event)">
                        {{format}}
                      </label>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="list-group-item" *ngIf="!offers && showOffersFilter">
                <div class="d-flex">
                  <div style="font-size: 1.25em">
                    <label>
                      <input type="checkbox" (change)="filterOfferFn()">
                      PROMOCIONES
                    </label>
                  </div>
                </div>
              </li>
            </div>
          </ul>
        </div>

        <!-- ORDENAR Y BUSCAR-->
        <div class="row mt-4">
          <div class="col-md-5">
            <div class="select">
              <select class="selectProductSort form-control" (change)="orderArticles($event.target)">
                <option value="name:asc" selected="selected">Nombre, de A a Z</option>
                <option value="name:desc">Nombre, de Z a A</option>
                <option value="price:asc">Precio, más baratos primero</option>
                <option value="price:desc">Precio, más caros primero</option>
              </select>
            </div>
          </div>

          <div class="col-md-7 d-flex justify-content-end">
            <div class="form-group filter-computer">
              <form>
                <input class="searchArticle form-control" type="text" [placeholder]="searchDefaultText"
                  id="article-search-computer" name="nombre" [(ngModel)]="searchArticles" name="searchArticles"
                  (keypress)="search()" (keyup)="search()" (change)="search()" (keydown)="search()">
              </form>
            </div>
          </div>

          <div class="col-md-7 d-flex">
            <div class="form-group w-100 filter-mobil-search">
              <input class="form-control w-100" type="text" [placeholder]="searchDefaultText" name="nombre"
                [(ngModel)]="searchArticles" id="article-search-mobil" (keypress)="search()" (keyup)="search()"
                (change)="search()" (keydown)="search()">
            </div>
          </div>
        </div>

        <!-- PAGINACIÓN ARRIBA-->
        <div class="d-flex justify-content-end mt-4"
          *ngIf="readyArticles && articles.length > 40 && companyConf.topPagination">
          <!-- <span>Mostrando <b class="text-info">{{numberArticlesPageCopy}}</b> de <b>{{articles.length}}</b>
                elementos, Página {{pageArticles}}/{{numberPage}}
          </span> -->

          <pagination-controls class="pagination justify-content-end font-weight-bold" previousLabel="Anterior"
            nextLabel="Siguiente" style="font-size: 12px" (pageChange)="pageChanged($event)"></pagination-controls>

        </div>

        <!--<div class="row mb-2">
          <div class="col-lg-8"></div>
          <div class="col-lg-4">
            <div class="d-flex justify-content-end w-100">
              <button class="btn btn-info"
                [ngClass]="{'btn-info': !verTresArticulosOrdenador, 'btn-outline-info': verTresArticulosOrdenador}"
                (click)="verTresArticulosOrdenador = false">
                <i class="fa-regular fa-square"></i>
                <i class="fa-regular fa-square ml-1"></i>
                <i class="fa-regular fa-square ml-1"></i>
                <i class="fa-regular fa-square ml-1"></i>
              </button>

              <button class="btn ml-2"
                [ngClass]="{'btn-info': verTresArticulosOrdenador, 'btn-outline-info': !verTresArticulosOrdenador}"
                (click)="verTresArticulosOrdenador = true">
                <i class="fa-regular fa-square"></i>
                <i class="fa-regular fa-square ml-1"></i>
                <i class="fa-regular fa-square ml-1"></i>
              </button>
            </div>
          </div>
        </div>-->

        <div class="row">
          <div class="col-md-4 col-6 mt-3 p-3"
            [ngClass]="{'col-md-3': !verTresArticulosOrdenador, 'col-md-4': verTresArticulosOrdenador}"
            *ngFor="let article of articles  | paginate: {itemsPerPage: 40, currentPage: pageArticles}; let pos = index"
            (click)="redirectToArticulo(article.groupName, article.name, article.group, article.family, article.id)">
            <div [ngClass]="{'border-container': companyConf.showArticleSquare == true}" class="h-100">
              <div *ngIf="article.articleImgUrl || article.productImgUrl" class="mb-2">
                <div (click)="article.search = setArticleSearchFalse()"
                  (mouseleave)="article.search = setArticleSearchFalse()">
                  <div class="w-100 d-flex justify-content-center">
                    <div class="grid-bg-img mt-2" *ngIf="article.articleImgUrl; else productImg"
                      [ngClass]="{'green-opacity': article.quantity > 0 && companyConf.companyException != 'ENJA'}"
                      [ngStyle]="{'background': 'url(assets/fotos/articulos/' + urlImg(pos, article.articleImgUrl).split(' ').join('%20') + ') center center / cover no-repeat', 'background-size': '100% auto'}">

                      <div *ngIf="article.quantity > 0 && companyConf.companyException != 'ENJA'" class="w-100 h-100 text-center font-cantidad"
                        style="margin-top: 35%">
                        <h1 style="font-size: 2.5em !important;">
                          <span *ngIf="article.format === '1'">
                            {{article.quantity | number: '1.2'}} Kg</span>
                          <span *ngIf="article.format !== '1'">
                            {{article.quantity}} Ud</span>
                        </h1>
                      </div>

                      <div class="position-absolute text-center w-100 pt-2" (click)="stopPropagation($event)"
                           *ngIf="numeroFotos.length == articles.length">
                        <span *ngFor="let a of arrayFromNumber(numeroFotos[pos]); let pos1 = index">
                          <span *ngIf="posicionesFotoSeleccionada[pos] == pos1; else elseNoSeleccinado">
                            <i class="fa-solid fa-circle pr-2"></i>
                          </span>
                          <ng-template #elseNoSeleccinado>
                            <i class="fa-regular fa-circle pr-2 pointer" (click)="posicionesFotoSeleccionada[pos] = pos1;"></i>
                          </ng-template>
                        </span>
                      </div>

                    </div>
                    <ng-template #productImg>
                      <div class="grid-bg-img mt-2" *ngIf="article.productImgUrl"
                        [ngStyle]="{'background': 'url(assets/fotos/productos/' + article.productImgUrl.split(' ').join('%20') + ') no-repeat', 'background-size': '100% auto'}">
                      </div>
                    </ng-template>
                  </div>

                </div>
              </div>

              <div class="d-flex justify-content-center align-items-center w-100 pb-0"
                *ngIf="!(article.articleImgUrl || article.productImgUrl)" style="height: 1.875em !important;">
                <span class="text-info font-weight-bold" *ngIf="article.offer === 1">
                  ¡Oferta!
                </span>
                <span class="ml-3 text-danger font-weight-bold" *ngIf="companyConf.showStock && article.stock <= 0">
                  Sin stock
                </span>
              </div>

              <div class="article-name w-100 mb-4" (click)="stopPropagation($event)" [ngClass]="{'font-weight-bold': companyConf.boldNameArticle,
                    'mb-4': (article.articleImgUrl || article.productImgUrl) != null && !(article.format),
                    'text-center': companyConf.centerNameArticle, 'text-left': !companyConf.centerNameArticle}">
                <h2 class="w-100" *ngIf="article.nameClient != '' && article.nameClient != undefined"
                    style="font-size: 1.1em !important;"
                  [ngClass]="{'name-height': companyConf.ponerCantidadMenuInicial}">
                  {{article.nameClient.toUpperCase()}}
                </h2>
                <h2 class="w-100" *ngIf="article.nameClient == '' || article.nameClient == undefined"
                    style="font-size: 1.1em !important;"
                  [ngClass]="{'name-height': companyConf.ponerCantidadMenuInicial}">
                  {{article.name.toUpperCase()}}
                </h2>
              </div>

              <div class="article-price text-left mb-2" *ngIf="article.format">
                <span [ngClass]="{'font-weight-bold': companyConf.boldPrice}"
                  [style]="'color: ' + companyConf.priceColor + ';'"
                  (click)="redirectToArticulo(article.groupName, article.name, article.group, article.family, article.id)">
                  {{article.priceIva | currency: 'EUR'}}
                  <span *ngIf="companyConf.companyException != 'ENJA'">
                    <span *ngIf="article.format === '1'">/Kg</span>
                    <span *ngIf="article.format !== '1'">/ud.</span>
                  </span>
                </span>
              </div>


              <div *ngIf="companyConf.ponerCantidadMenuInicial && companyConf.selectQuantity"
                (click)="stopPropagation($event)">
                <div class="select-container mt-2" *ngIf="article.format == 1; else articleUd">
                  <div class="d-flex justify-content-center w-100 mb-2">
                    <button class="btn btn-add text-dark"
                      [disabled]="article.quantity == undefined || article.quantity == 0"
                      (click)="subtractQuantity(1, article);  setQuantity(article);">
                      <i class="fa-solid fa-minus"></i>
                    </button>

                    <div class="quantity-div text-center pt-2">
                      <span *ngIf="article.quantity == undefined || article.quantity == 0; else elseQuantity">0</span>
                      <ng-template #elseQuantity>
                        {{(article.quantity | number: '.2').toString().replace('.', ',')}}
                      </ng-template>
                    </div>

                    <button class="btn btn-add text-dark"
                      [disabled]="selectKgs[selectKgs.length -1] == article.quantity"
                      (click)="addQuantity(1, article);  setQuantity(article);">
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>

                <ng-template #articleUd>
                  <div class="d-flex justify-content-center w-100 mb-2">
                    <button class="btn btn-add text-primary"
                      [disabled]="article.quantity == undefined || article.quantity == 0 || article.price == 0"
                      (click)="subtractQuantity(0, article); companyConf.companyException != 'ENJA' ? setQuantity(article) : false;">
                      <i class="fa-solid fa-minus"></i>
                    </button>

                    <div class="quantity-div text-center pt-2">
                      <span *ngIf="article.quantity == undefined || article.quantity == 0; else elseQuantity">0</span>
                      <ng-template #elseQuantity>
                        {{(article.quantity | number: '.2').toString().replace('.', ',')}}
                      </ng-template>
                    </div>

                    <button class="btn btn-add text-primary"
                      [disabled]="selectUnits[selectUnits.length -1] == article.quantity || article.price == 0"
                      (click)="addQuantity(0, article); companyConf.companyException != 'ENJA' ? setQuantity(article) : false;">
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </ng-template>



                <button class="btn btn-primary text-light w-100" *ngIf="companyConf.companyException == 'ENJA'"ç
                        (click)="setQuantity(article);">
                  Añadir al carrito
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- PAGINACIÓN ABAJO-->
        <div id="pagination-container" class="d-flex justify-content-center mb-3"
          *ngIf="readyArticles && articles.length > 40">
          <pagination-controls class="pagination justify-content-end font-weight-bold" previousLabel="Anterior"
            nextLabel="Siguiente" style="font-size: 12px" (pageChange)="pageChanged($event)"></pagination-controls>

        </div>

        <div class="w-100 text-right">
          Mostrando un total de {{articles.length}} artículos
        </div>
      </div>
    </div>
  </div>
</div>


<!-- BUSCAR EN TODOS LOS PRODUCTOS -->
<ng-template #SEARCH_PRODUCTS let-modal class="modal">
  <div class="modal-body">
    <div class="container-fluid">
      <div class="d-flex mb-2">
        <div class="font-weight-bold mr-4" style="width: 90%;">
          <h1 class="text-dark">Buscar en todos los articulos</h1>
        </div>
        <div class="d-flex justify-content-end" style="width: 10%" (click)="isOpenSearch=false; modal.close()">
          <i class="fas fa-times-circle fa-2x text-danger"></i>
        </div>
      </div>

      <form class="w-100">
        <input class="searchArticle form-control w-100" type="text" placeholder="{{searchDefaultText}}"
          id="article-search-popup" name="nombre" [(ngModel)]="searchArticles" name="searchArticles"
          (keypress)="search()" (keyup)="search()" (change)="search()" (keydown)="search()">
      </form>

      <div class="w-100 d-flex justify-content-center mt-4">
        <button type="button" class="btn btn-dark  btn-modal" (click)="isOpenSearch=false; modal.close()">
          ACEPTAR
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="align-items-center d-flex justify-content-center container-load w-100">
    <div class="w-100">
      <div class="w-100 d-flex justify-content-center mb-3">
        <img src="assets/loading/loading.gif"  alt="Cargando icono" title="Icono cargando datos"
          style="height: 150px !important; width: auto !important;" />
      </div>
      <div class="text-center">
        <p style="font-size: 14px">Espere un momento</p>
      </div>
    </div>
  </div>
</ng-template>
