import {Component, HostListener, Input, ViewChild} from '@angular/core';
import {Group, SuperGroup} from '../../../interfaces/group';
import {Router} from '@angular/router';
import {takeWhile, tap} from 'rxjs/operators';
import {interval} from 'rxjs';

@Component({
  selector: 'app-carousel-groups',
  templateUrl: './carousel-groups.component.html',
  styleUrls: ['./carousel-groups.component.scss']
})
export class CarouselGroupsComponent {
  constructor(private router: Router) { }

  @Input() superGroups: Array<SuperGroup>;
  @ViewChild('left') left;
  @ViewChild('myname') input;
  innerWidth = window.innerWidth;

  getArticlesGroup(superGroup: SuperGroup, group: Group) {
  if (group.id.toLowerCase().trim() == 'of') {
  this.redirectTo('/compra/ofertas' );
  } else if (superGroup.id === '') {
  this.redirectToGrupo(group.name,'none', group.id );
  } else {
  this.redirectToGrupo(group.name,superGroup.id, group.id);
  }
  }

  redirectToGrupo(nombre: string, superGrupo: string, grupo: string) {
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
  this.router.navigate([
    '/compra/' + nombre.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('')
  ], {
    queryParams: { superGrupo: superGrupo, grupo: grupo }
  }));
  }

  redirectTo(uri: string) {
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
  this.router.navigate([uri], {state: {topArticle: true}}));
  }

  scrollLeft(el: Element) {
  const animTimeMs = 400;
  const pixelsToMove = 315;
  const stepArray = [0.001, 0.021, 0.136, 0.341, 0.341, 0.136, 0.021, 0.001];
  interval(animTimeMs / 8)
  .pipe(
    takeWhile(value => value < 8),
    tap((value: string | number) => el.scrollLeft -= (pixelsToMove * stepArray[value])),
  )
  .subscribe();
  }

  showArrow(el: Element, container: Element) {
  return el.scrollWidth > (container.scrollWidth - container.scrollWidth * 0.08);
  }

  scrollRight(el: Element) {
  const animTimeMs = 400;
  const pixelsToMove = 315;
  const stepArray = [0.001, 0.021, 0.136, 0.341, 0.341, 0.136, 0.021, 0.001];
  interval(animTimeMs / 8)
  .pipe(
    takeWhile(value => value < 8),
    tap(value => el.scrollLeft += (pixelsToMove * stepArray[value])),
  )
  .subscribe();
  }

  @HostListener('window:resize')
  onResize() {
  this.innerWidth = window.innerWidth;
  }
}
