import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Import Containers
import {NotUserGuard} from './@core/guards/not-user.guard';
import {ResetPasswordGuard} from './@core/guards/reset-password.guard';

  export const routes: Routes = [
  // Login y registro
  { path: 'iniciarSesion', canActivate: [NotUserGuard], loadChildren: () => import('./@core/components/auth/login/login.module').then(m => m.LoginModule) },
  { path: 'registrarse', loadChildren: () => import('./@core/components/auth/register/register.module').then(m => m.RegisterModule) },
  { path: 'nuevaClave/:randomUrl', canActivate: [ResetPasswordGuard], loadChildren: () => import('./@core/components/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  { path: '404', data: { title: 'Page 404' }, loadChildren: () => import('./@core/components/errors/e404.module').then(m => m.E404Module) },
  { path: '500', data: { title: 'Page 500' }, loadChildren: () => import('./@core/components/errors/e500.module').then(m => m.E500Module) },

  // Politica de privacidad, aviso legal y cookies
  { path: 'politica-privacidad', loadChildren: () => import('./@core/components/footer/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  { path: 'aviso-legal', loadChildren: () => import('./@core/components/footer/legal-notice/legal-notice.module').then(m => m.LegalNoticeModule) },
  { path: 'aviso-cookies', loadChildren: () => import('./@core/components/footer/cookies/cookies.module').then(m => m.CookiesModule) },
  {
  path: '',
  loadChildren: () => import('./container/default-container/default-container.module').then(m => m.DefaultContainerModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
  initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
