<span *ngIf="navigationRoute">
  {{newNavigationFn()}}
</span>

<!-- ARTICLE COMPONENT-->
<div id="container" class="p-2">
  <!-- CÓDIGO PARA MOSTRAR LAS IMÁGENES DEL ARTICULO-->
  <div class="row" *ngIf="(article.articleImgUrl != '' && article.articleImgUrl != null) ||  (article.productImgUrl != '' && article.productImgUrl != null)">
    <div class="col-md-6 col-12" *ngIf="article  && !dropDownGauge">
      <div class="d-flex justify-content-center w-100">
        <img *ngIf="article.articleImgUrl; else elseProductImage"
             class="img mb-3" src="./assets/fotos/articulos/{{urlImg(article.articleImgUrl)}}"  alt="{{article.name}}" width="100%" height="auto"/>
        <ng-template #elseProductImage>
          <img *ngIf="article.productImgUrl;"
               class="img mb-3" src="./assets/fotos/productos/{{article.productImgUrl}}"  alt="{{article.name}}"  width="100%" height="auto"/>
        </ng-template>

        <div class="position-absolute text-center w-100 pt-2" *ngIf="numeroFotos > 1">
          <span *ngFor="let a of arrayFromNumber(numeroFotos); let pos1 = index">
            <span *ngIf="posicionesFotoSeleccionada == pos1; else elseNoSeleccinado">
              <i class="fa-solid fa-circle pr-2"></i>
            </span>
            <ng-template #elseNoSeleccinado>
              <i class="fa-regular fa-circle pr-2 pointer" (click)="posicionesFotoSeleccionada = pos1;"></i>
            </ng-template>
          </span>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-12">
      <div *ngIf="productArticles" class="p-1 w-100 text-center">
    <span class="text-center w-100 text-info" *ngIf="productArticles.information">
      {{productArticles.information}}
    </span>
      </div>

      <div class="article-name d-flex">
        <h1 class="text-dark name" *ngIf="article.nameClient == '' || article.nameClient == undefined;  else elseNameClient">
          {{article.name.toUpperCase()}}
          <span class="pointer" style="border: 1px solid black;" *ngIf="article.articleTag" (click)="openModalTag(tagArticle)">
    <i class=" fas fa-barcode p-1"></i>
    </span>
        </h1>

        <ng-template #elseNameClient>
          <h1 class="text-dark name">
            {{article.nameClient.toUpperCase()}}
            <span class="pointer" style="border: 1px solid black;" *ngIf="article.articleTag" (click)="openModalTag(tagArticle)">
      <i class=" fas fa-barcode p-1"></i>
    </span>
          </h1>
        </ng-template>
      </div>

      <div class="price" style="font-size: 1.5em"
          [ngClass]="{'font-weight-bold': companyConf.boldPrice}"
          [style]="'color: ' + companyConf.priceColor + ';'">
        <span>{{article.priceIvaStr.toString().replace('.',',')}} €</span>
        <span *ngIf="companyConf.companyException != 'ENJA'">
          <span *ngIf="article.format === '1'">/Kg</span>
          <span *ngIf="article.format !== '1'">/ud.</span>
        </span>
      </div>

      <div *ngIf="article.procedimientos" class="new-line p-1 mb-2"  >{{article.procedimientos}}</div>
      <div *ngIf="article.ingredients" class="new-line p-1 mb-2"  ><b>Ingredientes:</b> <br/>{{article.ingredients}}</div>
      <div *ngIf="article.conservantes" class="new-line p-1 mb-2"  ><b>Conservantes:</b> <br/>{{article.conservantes}}</div>
      <div id="alergenos" class="mt-2 ml-2 d-flex" *ngIf="article.imagenesAlergenos != undefined">
        <div *ngFor="let imagenAlergeno of article.imagenesAlergenos" class="mr-2">
          <img class="img" src="./assets/fotos/alergenos/{{imagenAlergeno}}"
               alt="{{imagenAlergeno.replace('.png', '')}}"  width="60px" height="auto"/>

        </div>
      </div>

      <form  [formGroup]="cutForm" novalidate>
        <div *ngIf="productArticles">
          <div class="form-group" *ngIf="productArticles.dropDownCuts.length > 0">
            <ng-select bindLabel="name"
                       formControlName="cut" (change)="cutValidFn()"

                       placeholder="Seleccione una preparación">
              <ng-option *ngFor="let cut of productArticles.dropDownCuts" [value]="cut.id">{{cut.name}}</ng-option>
            </ng-select>
          </div>
        </div>
      </form>

      <div class="select-container" *ngIf="selectFormat == 1">
        <form (ngSubmit)="openModalArticle(addArticle)" [formGroup]="quantityKgForm" novalidate>
          <div class="form-group">
            <div *ngIf="!companyConf.selectQuantity">
              <div>
                <label>Indique una cantidad en <b>Kilos</b></label>
                <input type="number" class="form-control input-price"
                       placeholder="Ej: 1,5" formControlName="quantity" *ngIf="!useMinMax"
                       (change)="calculatePriceKg()"  (keyup)="calculatePriceKg()">

                <input type="number" class="form-control input-price"
                       *ngIf="useMinMax"
                       placeholder="Mínimo {{minimum}}kg"
                       formControlName="quantity" [min]="minimum"
                       (change)="calculatePriceKg()"  (keyup)="calculatePriceKg()">
              </div>
            </div>

            <div *ngIf="companyConf.selectQuantity" class="w-100">
              <label class="w-100">Indique una cantidad en <b>Kilos</b>*
                <select formControlName="quantity" class="browser-default custom-select"
                        (change)="calculatePriceKg()">
                  <option [value]="kg" *ngFor="let kg of selectKgs">
                    {{kg | number : '1.2-2'}} Kg
                  </option>
                </select>
              </label>
            </div>
            <div *ngIf="quantityKgForm.controls.quantity.touched && quantityKgForm.controls.quantity.errors?.required"
                 class="error">Campo obligatorio.
            </div>
            <div *ngIf="quantityKgForm.controls.quantity.touched && quantityKgForm.controls.quantity.errors?.pattern"
                 class="error">Formato incorrecto, máximo un decimal
            </div>
            <div *ngIf="quantityKgForm.controls.quantity.touched && !quantityKgForm.controls.quantity.errors?.required
        && quantityKgForm.controls.quantity.errors?.errorMinLimits"
                 class="error">La cantidad debe ser mínimo {{minimum}}kg
            </div>

            <div class="mt-2" style="font-size: 1.25em;">
              Precio total: <b>{{(totalPrice | currency: 'EUR')}}</b>
            </div>
          </div>

          <div *ngIf="indicateLumps && useMinMax == true">
            <div class="d-flex mb-2">
              Número de unidades
              <div class="ml-2 help text-info" (click)="openModal(INFOUSER)">
                <i class="help fas fa-question-circle"></i>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="btn btn-dark text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                   (click)="addValue()"> + </div>
              <div class="form-group ml-2 mr-2" style="width: 3.75em;">
                <span class="w-100 form-control text-center"> {{numberPieces}} </span>
              </div>
              <div class="btn btn-dark text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                   (click)="subtractValue()"> - </div>
            </div>
          </div>
          <div *ngIf="productArticles; else elseBlock">
            <div *ngIf="productArticles.dropDownCuts.length > 0; else elseBlock">
              <button type="submit" class="btn btn-primary text-light btn-block"
                      [disabled]="!quantityKgForm.valid || !cutValid || !letAddArticle || article.price == 0" ngbAutofocus >Añadir al carrito</button>
            </div>
          </div>
          <ng-template #elseBlock>
            <button type="submit" class="btn btn-primary text-light btn-block"
                    [disabled]="!(quantityKgForm.valid || quantityUdForm.valid) || !letAddArticle || article.price == 0" ngbAutofocus >
              Añadir al carrito
            </button>
          </ng-template>
          <div *ngIf="!letAddArticle" class="error">
            Ya se ha añadido la cantidad máxima permitida al carrito.
          </div>
        </form>
      </div>

      <div class="select-container"  *ngIf="selectFormat != 1">
        <form (ngSubmit)="openModalArticle(addArticle)" [formGroup]="quantityUdForm" novalidate>
          <div class="form-group">
            <label class="w-100">
              <input *ngIf="!companyConf.selectQuantity"
                     class="input-price form-control" type="number" placeholder="Indique el número de {{selectFormatName}}*"
                     formControlName="quantity" min="1" step="1" value="1" (change)="calculatePriceUd()"
                     (keyup)="calculatePriceUd()">
            </label>

            <div *ngIf="companyConf.selectQuantity && companyConf.companyException != 'ENJA'" class="w-100">
              <label class="w-100">Indique el número de <b>{{selectFormatName}}</b>*
                <select formControlName="quantity" class="browser-default custom-select"
                        (change)="calculatePriceUd()">
                  <option [value]="unit" *ngFor="let unit of selectUnits">
                    <span *ngIf="unit == 1; else elseUnit1">
                      {{unit}} unidad
                    </span>
                    <ng-template #elseUnit1>
                      {{unit}} unidades
                    </ng-template>
                  </option>
                </select>
              </label>
            </div>

            <div class="select-container mt-2" *ngIf="companyConf.companyException == 'ENJA'">
              <div class="d-flex justify-content-center w-100 mb-2">
                <button  type="button" class="btn btn-add text-dark mr-2"
                         [disabled]="this.quantityUdForm.controls['quantity'].value == undefined || this.quantityUdForm.controls['quantity'].value == 0"
                         (click)="subtractQuantity(2); calculatePriceUd();">
                  <i class="fa-solid fa-minus"></i>
                </button>

                <div class="quantity-div text-center  h-100">
                  <input type="text" class="text-center text-dark bg-light-gray font-size-title p-2 h-100" style="max-width: 100px" formControlName="quantity" readonly>
                </div>

                <button  type="button" class="btn btn-add text-dark ml-2"
                         [disabled]="selectKgs[selectKgs.length -1] == this.quantityUdForm.controls['quantity'].value"
                         (click)="addQuantity(2); calculatePriceUd();">
                  <i class="fa-solid fa-plus"></i>
                </button>
              </div>
            </div>

            <div *ngIf="quantityUdForm.controls.quantity.touched && quantityUdForm.controls.quantity.errors?.required"
                 class="error">Campo obligatorio.
            </div>
            <div *ngIf="quantityUdForm.controls.quantity.touched && quantityUdForm.controls.quantity.errors?.pattern"
                 class="error">Formato incorrecto. Solo se aceptan números enteros.
            </div>
            <span style="margin-top: 10px">
              Precio total: <b>{{(totalPrice| currency: 'EUR')}}</b>
            </span>
          </div>

          <div *ngIf="productArticles; else elseBlock5">
            <div *ngIf="productArticles.dropDownCuts.length > 0; else elseBlock5">
              <button type="submit" class="btn btn-primary text-light btn-block"
                      [disabled]="!quantityUdForm.valid || !cutValid || !letAddArticle || article.price == 0" ngbAutofocus >Añadir al carrito</button>
            </div>
          </div>
          <ng-template #elseBlock5>
            <button type="submit" class="btn btn-primary text-light btn-block"
                    [disabled]="!quantityUdForm.valid || !letAddArticle || article.price == 0" ngbAutofocus >Añadir al carrito</button>
          </ng-template>
          <div *ngIf="!letAddArticle" class="error">
            Ya se ha añadido la cantidad máxima permitida al carrito.
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div *ngIf="productArticles" class="w-100 p-2 mt-3">
  <div *ngIf="productArticles.dropDownCuts.length > 0" style="border: 1px solid lightgray">
    <h3 class="font-weight-bold mb-0">Tipos de preparación</h3>
  </div>
</div>

<!-- ARTICLE COMPONENT-->
<div class="scrolling-wrapper" *ngIf="productArticles">
  <div *ngFor="let cut of productArticles.dropDownCuts" style="width: 11.25em"
       class="ml-2 pointer" (click)="this.cutForm.controls['cut'].setValue(cut.id, {onlySelf: true}); cutValidFn()">
    <img *ngIf="cut.img" [src]="cut.img" style="width: 11.25em; height: 11.25em" class="img" alt="Corte {{cut.name}}"/>
    <div class="text-center" >
      <h3 class="font-weight-bold mb-0 text-black-50">{{cut.name}}</h3>
    </div>
  </div>
</div>

<!-- Add article POP UP -->
<ng-template #addArticle let-modal class="modal">
  <div class="modal-body">
    <div class="w-100 d-flex justify-content-end">
      <div class="text-danger" (click)="closeModal(modal)">
        <i class="fas fa-times-circle fa-2x"></i>
      </div>
    </div>
    <div class="row" style="margin-bottom: 10px">
      <div class="col-md-6 border-right modal-info">
        <div class="w-100 text-center font-weight-bold" style="margin-bottom: 10px">
          ARTÍCULO AÑADIDO AL CARRITO
        </div>
        <div class="w-100 d-flex justify-content-center" *ngIf="article.articleImgUrl || article.productImgUrl" >
          <div style="height: 100px; min-width: 100px" id="container-img-success">
            <img *ngIf="article.articleImgUrl; else productImg"
                 style="height: 100px; width: 100px"
                 src="./assets/fotos/articulos/{{article.articleImgUrl}}" alt="{{article.name}}"/>
            <ng-template #productImg>
              <img *ngIf="article.productImgUrl"
                   style="height: 100px; width: 100px"
                   src="./assets/fotos/productos/{{article.productImgUrl}}"  alt="{{article.name}}"/>
            </ng-template>
          </div>
        </div>
        <div class="w-100 text-center" *ngIf="article.nameClient == '' ||  article.nameClient == undefined && !dropDownGauge; else nameClient">{{article.name.toUpperCase()}}</div>
        <ng-template #nameClient>
          <div class="w-100 text-center" *ngIf="!dropDownGauge">{{article.nameClient.toUpperCase()}}</div>
        </ng-template>
        <div class="w-100 text-center" *ngIf="dropDownGauge">{{productArticles.name}}</div>
      </div>

      <div class="col-md-6 d-flex justify-content-center modal-info" *ngIf="summary">
        <div class="text-center" style="margin-bottom: 1.25em">
          <h3 class="text-dark font-weight-bold">
            <i class="fas fa-shopping-cart"></i>
            Hay <span class="text-danger">{{summary.numArticles}}</span> artículos en su carrito.<br/>
          </h3>
          <div style="margin-top:-10px"></div>
          <div style="margin-top:-10px"></div>

          <div *ngIf="differenceMinPrice <= 0 && priceRoute.minKg == 0 && priceRoute.deliveryPrice == 0 && priceRoute.deliveryKg == 0">
            <div *ngIf="homeDelivery; else pickUpStore">
              Entrega <span class="text-danger">GRATUITA</span><br/>
            </div>
            <ng-template #pickUpStore>
              RECOGIDA EN TIENDA
            </ng-template>
          </div>
          <div *ngIf="differenceMinPrice > 0 && priceRoute.minKg == 0 && priceRoute.deliveryPrice == 0 && priceRoute.deliveryKg == 0">
            <span style="font-size: 12px">Entrega no disponible:</span>
            <span class="text-danger"> Faltan {{(differenceMinPrice | currency: 'EUR')}}</span><br/>
          </div>

          <div *ngIf="!(priceRoute.minKg == 0 && priceRoute.deliveryPrice == 0 && priceRoute.deliveryKg == 0) && totalTransport > 0">
            <span style="font-size: 12px" *ngIf="priceRoute.deliveryKg != 0 && priceRoute.deliveryPrice != 0">
              Precio transporte:
              {{(priceRoute.deliveryPrice  | currency: 'EUR')}}
              cada {{priceRoute.deliveryKg}}Kg<br/><br/>
            </span>
            <span style="font-size: 12px" *ngIf="priceRoute.minKg != 0">
              Por compras inferiores a {{priceRoute.minKg}}Kg no se realizan envios.<span class=""></span>
              <br/><br/>
            </span>
            <span style="font-size: 0.875em;" class="font-weight-bold">
                TRANSPORTE:
                <span class="font-weight-light">
                  {{(totalTransport  | currency: 'EUR')}}.
                </span>
            </span>
          </div>

          <div class="item-info" *ngIf="pendientePago < 0">
            DESCUENTO acumulado
            <span class="text-danger font-weight-bold"> {{pendientePago | currency: 'EUR'}} </span>
          </div>

          <div>
            <b>TOTAL</b><span style="font-size: 12px"> (IMPUESTOS INC.) </span>
            <span [ngClass]="{'font-weight-bold': companyConf.boldPrice}"
                  [style]="'color: ' + companyConf.priceColor + ';'">{{(summary.totalPriceIva  | currency: 'EUR')}}</span><br/>
          </div>
        </div>
      </div>
    </div>

    <div class="row" id="buttons">
      <div class="col-md-6 d-flex justify-content-center">
        <button type="button" class="btn btn-dark  btn-modal"
                (click)="scrollTop(); modal.close(); navigateToGroup(article)">
          CONTINUAR COMPRANDO
        </button>
      </div>
      <div class="col-md-6 d-flex justify-content-center">
        <button type="button" class="btn btn-success  btn-modal"
                (click)="scrollTop(); modal.close()" routerLink="/carrito">
          VER CARRITO
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Add article POP UP -->
<ng-template #tagArticle let-modal class="modal">
  <div class="modal-body">
    <div class="w-100 d-flex justify-content-end">
      <div class="text-danger" (click)="modal.close()">
        <i class="fas fa-times-circle fa-2x"></i>
      </div>
    </div>

    <div class="d-flex w-100 p-2" style="border: 1px solid black;">
      <div class="w-50 mr-2" style="border-right: 1px solid black;" *ngIf="productArticles.treatment">Producto: <b>{{productArticles.treatment}}</b></div>
      <div class="w-50" *ngIf="productArticles.obtaining">Método de producción: <b>{{productArticles.obtaining}}</b></div>
    </div>

    <div class="w-100 p-2" style="border-bottom: 1px solid black;border-right: 1px solid black;border-left: 1px solid black;">
      <div *ngIf="productArticles.fao" class="w-100 d-flex justify-content-end">FAO: <b>{{productArticles.fao}}</b></div>
      <div *ngIf="productArticles.name">Denominación comercial: <b>{{productArticles.name}}</b></div>
      <div *ngIf="productArticles.scientificName">Denominación científica: <b>{{productArticles.scientificName}}</b></div>
    </div>

    <div class="w-100 p-2" style="border-bottom: 1px solid black;border-right: 1px solid black;border-left: 1px solid black;">
      <div *ngIf="productArticles.capture">Zona de captura o cría: <b>{{productArticles.capture}}</b></div>
      <div *ngIf="productArticles.fishingGear">Arte de pesca: <b>{{productArticles.fishingGear}}</b></div>
    </div>

    <div class="w-100 p-2" style="border-bottom: 1px solid black;border-right: 1px solid black;border-left: 1px solid black;">
      <div *ngIf="productArticles.allergies">Alérgenos: <b>{{productArticles.allergies}}</b></div>
    </div>
  </div>
</ng-template>


<!-- Info User -->
<ng-template #INFOUSER let-modal class="modal">
  <div class="modal-body">
    <div class="container">
      <div class="d-flex mb-2">
        <div class="font-weight-bold mr-4" style="width: 90%;">
          <h1 class="text-info">Número de unidades</h1>
        </div>
        <div class="d-flex justify-content-end"
             style="width: 10%">
          <a (click)="modal.close()" class="text-danger">
            <i class="fas fa-times-circle fa-2x"></i>
          </a>
        </div>
      </div>

      <div class="max" style="margin-bottom: 10px">
        <h4 CLASS="font-weight-bold mb-0">Atención</h4>
        <span>El número de unidades solicitadas puede ser variable dependiendo de la disponibilidad de las piezas.</span>
        <br/>
      </div>
    </div>
  </div>
</ng-template>

