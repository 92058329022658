import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {endpoints} from '../../../../environments/endpoints';
import {AppConfig} from "../../../app.config";

const httpOptions = {};

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  environmentUrl;
  constructor(private httpClient: HttpClient, private config: AppConfig) {
  this.environmentUrl = this.config.getConfig('host');
  }

  login(user: string, password: string) {
  return this.httpClient.post(this.environmentUrl + endpoints.login, {
  User: user,
  Password: password
  }, httpOptions);
  }

  validateToken() {
  return this.httpClient.get(this.environmentUrl + endpoints.validateToken, {});
  }

  refreshToken() {
  return this.httpClient.get(this.environmentUrl + endpoints.refreshToken, {});
  }

  getTokenClient(user: string) {
  return this.httpClient.get(this.environmentUrl + endpoints.getTokenClient + '/' + user, httpOptions);
  }

  getUser() {
  return this.httpClient.get(this.environmentUrl + endpoints.getUser);
  }

  getClients(user: string) {
  return this.httpClient.post(this.environmentUrl + endpoints.getClients, {
  user: user
  });
  }

  getVendors(vendor: string) {
  return this.httpClient.post(this.environmentUrl + endpoints.getVendors, {
  value: vendor
  });
  }

  getClientCommercial(client: string, commercial: string) {
  return this.httpClient.post(this.environmentUrl + endpoints.getClientCommercial, {
  Client: client,
  Commercial: commercial
  });
  }

  registerClient(name: string, direction: string, country: string, province: string, city: string, phone1: string, phone2: string,
       birthday: string, email: string, password: string, observations: string) {
  if (observations != null){
  observations = observations.replace('\'', '`');
  }

  return this.httpClient.post(this.environmentUrl + endpoints.registerUser, {
  Name: name.replace('\'', '`'),
  Direction: direction.replace('\'', '`'),
  Country: country,
  Province: province,
  City: city,
  Phone1: phone1,
  Phone2: phone2,
  Birthday: birthday,
  Email: email,
  Password: password,
  Observation: observations,
  }, httpOptions);
  }

  registerCompany(name: string, direction: string, country: string, province: string, city: string, phone1: string, phone2: string,
      email: string, password: string, observations: string, cif: string, regimenFiscal: string, businessType: string) {
  if (observations != null){
  observations = observations.replace('\'', '`');
  }

  return this.httpClient.post(this.environmentUrl + endpoints.registerCompany, {
  Name: name.replace('\'', '`'),
  Direction: direction.replace('\'', '`'),
  Country: country,
  Province: province,
  City: city,
  Phone1: phone1,
  Phone2: phone2,
  Email: email,
  Password: password,
  Observation: observations,
  CIF: cif,
  RegimenFiscal: regimenFiscal,
  TipoNegocio: businessType,
  }, httpOptions);
  }

  // _______________________ DIRECTIONS ________________________________
  getUserDirections() {
  return this.httpClient.get(this.environmentUrl + endpoints.getUserDirections);
  }

  sendPasswordEmail(email: string) {
  return this.httpClient.post(this.environmentUrl + endpoints.sendPasswordEmail, {
  Email: email
  });
  }

  newDirection(country: string, province: string, city: string, direction: string, phone: string) {
  return this.httpClient.post(this.environmentUrl + endpoints.newDirection, {
  Country: country,
  Province: province,
  City: city,
  Direction: direction.replace('\'', '`'),
  Phone: phone
  }, httpOptions);
  }

  deleteUserDirection(country: string, province: string, city: string, direction: string) {
  return this.httpClient.post(this.environmentUrl + endpoints.deleteUserDirection, {
  Country: country,
  Province: province,
  City: city,
  Direction: direction
  });
  }

  updatePostalDirection(country: string, province: string, city: string, direction: string) {
  return this.httpClient.post(this.environmentUrl + endpoints.updatePostalDirection, {
  Country: country,
  Province: province,
  City: city,
  Direction: direction.replace('\'', '`')
  });
  }

  updateDirection(country: string, province: string, city: string, direction: string,
      updateCountry: string, updateProvince: string, updateCity: string, updateDirection: string,
      updatePhone: string) {
  return this.httpClient.post(this.environmentUrl + endpoints.updateDirection, {
  Direction: {
    Country: country,
    Province: province,
    City: city,
    Direction: direction.replace('\'', '`'),
  },
  Update: {
    Country: updateCountry,
    Province: updateProvince,
    City: updateCity,
    Direction: updateDirection.replace('\'', '`'),
    Phone: updatePhone,
  }
  });
  }

  // _______________________ ORDERS ________________________________
  obtenerPedidosSinServir() {
  return this.httpClient.get(this.environmentUrl + endpoints.obtenerPedidosSinServir);
  }

  modificarPedidoSinServir(array: any) {
  return this.httpClient.post(this.environmentUrl + endpoints.modificarPedidoSinServir, array);
  }

  getUserOrders() {
  return this.httpClient.get(this.environmentUrl + endpoints.getUserOrders);
  }

  getUserOrdersByDateRange(startDate: string, endDate: string) {
  return this.httpClient.post(this.environmentUrl + endpoints.getUserOrdersByDateRange,{
  startDate,
  endDate
  });
  }

  getMostRequestedArticles() {
  return this.httpClient.get(this.environmentUrl + endpoints.getMostRequestedArticles);
  }

  getMostRequestedArticlesBySuperGroup(superGroup: string) {
  return this.httpClient.get(this.environmentUrl + endpoints.getMostRequestedArticlesBySuperGroup + '/' + superGroup);
  }

  // _______________________ SET PASSWORD ________________________________
  setPassword(password, newPassword) {
  return this.httpClient.post(this.environmentUrl + endpoints.setPassword, {
  Password: password,
  NewPassword: newPassword
  });
  }

  // _______________________ SET PROFILE ________________________________
  editProfile(phone1, phone2, email, observation) {
  return this.httpClient.post(this.environmentUrl + endpoints.editProfile, {
  Phone1: phone1,
  Phone2: phone2,
  Email: email,
  Observation: observation
  });
  }

  // _______________________ SET PASSWORD ________________________________
  validatePasswordUrl(randomUrl) {
  return this.httpClient.post(this.environmentUrl + endpoints.validatePasswordUrl, {url: randomUrl}, httpOptions);
  }

  renewPassword(url, password) {
  return this.httpClient.post(this.environmentUrl + endpoints.renewPassword, {
  url,
  password
  }, httpOptions);
  }

  obtenerPerfilEmpresa() {
  return this.httpClient.get(this.environmentUrl + endpoints.obtenerPerfilEmpresa);
  }


  bloquearCliente() {
  return this.httpClient.get(this.environmentUrl + endpoints.bloquearCliente);
  }
}
