import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { endpoints} from '../../../environments/endpoints';
import {AppConfig} from "../../app.config";

@Injectable({
  providedIn: 'root'
})
export class DefaultService {
  environmentUrl;
  constructor(private httpClient: HttpClient, private config: AppConfig) {
  this.environmentUrl = this.config.getConfig('host');
  }

  validConnection() {
  return this.httpClient.get(this.environmentUrl + endpoints.validConnection, {});
  }

  getLanguages() {
  return this.httpClient.get(this.environmentUrl + endpoints.getLanguages, {});
  }

  getAllDirections() {
  return this.httpClient.get(this.environmentUrl + endpoints.getCoutries, {});
  }

  getCompanyConfig() {
  return this.httpClient.get(this.environmentUrl + endpoints.companyConfig, {});
  }

  getBasicCompanyConfig() {
  return this.httpClient.get(this.environmentUrl + endpoints.basicCompanyConfig, {});
  }

  getCompanyInfo() {
  return this.httpClient.get(this.environmentUrl + endpoints.companyData, {});
  }

  getSuperGroups() {
  return this.httpClient.get(this.environmentUrl + endpoints.superGroups, {});
  }

  getFamilies() {
  return this.httpClient.get(this.environmentUrl + endpoints.families, {});
  }

  getNewArticles(language: string, superGroup: string, group: string, offers: boolean, all: boolean) {
  return this.httpClient.post(this.environmentUrl + endpoints.getNewArticles, {
  language: language,
  superGroup: superGroup,
  group: group,
  offers: offers,
  all: all
  });
  }

  obtenerLoMasPedidoPorTodosLosClientes() {
  return this.httpClient.get(this.environmentUrl + endpoints.obtenerLoMasPedidoPorTodosLosClientes);
  }

  getBusinessTypes() {
  return this.httpClient.get(this.environmentUrl + endpoints.negocios);
  }

  getRegimenesFiscales() {
  return this.httpClient.get(this.environmentUrl + endpoints.regimenesFiscales);
  }

  getNumberOfArticles() {
  return this.httpClient.get(this.environmentUrl + endpoints.getNumberOfArticles);
  }

  getNumberOfArticlesOfSuperGroup(superGroup: string) {
  return this.httpClient.get(this.environmentUrl + endpoints.getNumberOfArticles + '/' + superGroup);
  }

  getSCSSAttributes() {
  return this.httpClient.get(this.environmentUrl + endpoints.getSCSSAttributes, {});
  }
}
