<div class="container" style="min-height: 65vh">
  <div class="row" style="margin-bottom: 1.25em">
  <div class="col-md-4">
  <div class="card">
    <div class="card-body">
    <div class="d-flex justify-content-center">
    <div style="margin-right: 1.25em">
      <i class="fas fa-user-circle fa-5x"></i>
    </div>

    <div class="mt-2 w-100 text-center align-middle">
      {{user.name}} <br/>
      {{user.birthday}} <br/>
    </div>
    </div>

    <!-- DATOS DE PERFIL FIJOS -->
    <div *ngIf="!editProfile">
    <div class="computer">
      <div class="row" style="margin-top: 15px">
      <div class="col-sm-12">
      <mat-form-field style="width: 100%">
        <mat-label>Teléfono Principal</mat-label>
        <input type="text" class="w-100"
         matInput readonly [value]="user.phone">
      </mat-form-field>
      </div>
      </div>

      <div class="row">
      <div class="col-sm-12">
      <mat-form-field style="width: 100%">
        <mat-label>Teléfono Secundario</mat-label>
        <input type="text" class="w-100"
         matInput readonly [value]="user.phone2">
      </mat-form-field>
      </div>
      </div>

      <div class="row">
      <div class="col-sm-12">
      <mat-form-field style="width: 100%">
        <mat-label>Correo electrónico</mat-label>
        <input type="text" class="w-100"
         matInput readonly [value]="user.email">
      </mat-form-field>
      </div>
      </div>

      <div class="row">
      <div class="col-sm-12">
      <mat-form-field style="width: 100%">
        <mat-label>Observaciones</mat-label>
        <textarea type="text" class="w-100" matTextareaAutosize
          matInput readonly [value]="user.observations"></textarea>
      </mat-form-field>
      </div>
      </div>

      <div class="row" *ngIf="user.pendientePago < 0">
      <div class="col-sm-12">
      <mat-form-field style="width: 100%">
        <mat-label>Descuento acumulado</mat-label>
        <input type="text" class="w-100 text-danger"  matInput readonly value="{{user.pendientePago | currency: 'EUR'}}">
      </mat-form-field>
      </div>
      </div>
    </div>

    <button class="btn btn-primary text-light mt-2 w-100" (click)="editProfile = true">Editar perfil</button>
    </div>

    <div *ngIf="editProfile">
    <!-- FORM CAMBIAR DATOS DE PERFIL -->
    <form (ngSubmit)="setProfileInfo()" [formGroup]="userForm" novalidate>
      <div class="row mt-3">
      <div class="col-sm-12">
      <mat-form-field class="w-100">
        <mat-label>Teléfono</mat-label>
        <input type="text" class="w-100" autocomplete="phone"
         formControlName="phoneNumber" matInput (keyup)="errorPhone = null">
        <div *ngIf="userForm.controls.phoneNumber.touched && userForm.controls.phoneNumber.errors?.required"
         class="error">Campo obligatorio.
        </div>
        <div *ngIf="userForm.controls.phoneNumber.touched && userForm.controls.phoneNumber.errors?.pattern"
         class="error">Formato incorrecto.
        </div>
        <div *ngIf="errorPhone" class="error">{{errorPhone}}</div>
      </mat-form-field>
          </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <mat-form-field   class="w-100">
                    <mat-label>Teléfono secundario</mat-label>
                    <input type="text" class="w-100" autocomplete="phone"
                           formControlName="phoneNumber2" matInput>
                    <div *ngIf="userForm.controls.phoneNumber2.touched && userForm.controls.phoneNumber2.errors?.pattern"
                         class="error">Formato incorrecto.
                    </div>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <mat-form-field   class="w-100">
                    <mat-label>Correo electrónico</mat-label>
                    <input type="text" class="w-100" autocomplete="password" placeholder="Ej: correo@hotmail.com"
                           formControlName="email" matInput (keyup)="errorMail = null">
                    <div *ngIf="userForm.controls.email.touched && userForm.controls.email.errors?.email"
                         class="error">Formato incorrecto.
                    </div>
                    <div *ngIf="errorMail" class="error">{{errorMail}}</div>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <mat-form-field   class="w-100">
                    <mat-label>Observaciones</mat-label>
                    <textarea type="text" class="w-100" placeholder="Me gustaría que mi pedido..."
                              formControlName="observations" matInput></textarea>
                  </mat-form-field>
                </div>
              </div>

              <!-- Submit button -->
              <div class="d-flex justify-content-center">
                <button type="submit" class="btn btn-dark mr-3"
                        (click)="editProfile = false">Cancelar</button>
                <button type="submit" class="btn btn-primary text-light"
                        [disabled]="!userForm.valid">Aceptar</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div>
        <button class="btn btn-outline-danger mt-2 w-100" (click)="logOut()">
          <div class="d-flex align-items-center justify-content-center w-100 mt-1">
              <span>

                <h4 class="font-weight-bold mb-0"><i class="fas fa-sign-out-alt fa-1x"></i> Cerrar sesión</h4>
              </span>
          </div>
        </button>
      </div>
    </div>


    <div class="col-md-8" style="margin-top: 1.25em">
      <!-- MI CUENTA -->
      <h1>Mi cuenta</h1>
      <div class="row">
        <div class="col-md-4"  routerLink="/miPerfil/misPedidos">
          <button class="btn btn-outline-light text-dark
           border-color mt-2 w-100">
            <i class="fas fa-box-open fa-2x"></i>
            <h3 class="font-weight-bold">Mis Pedidos</h3>
            <span>
              Ver mis pedidos o repetir compras anteriores
            </span>
          </button>
        </div>
        <div class="col-md-4" *ngIf="!companyConf.puntosRecogida">
          <button class="btn btn-outline-light text-dark
           border-color mt-2 w-100" routerLink="/miPerfil/direcciones">
            <i class="fas fa-map-marker-alt fa-2x"></i>
            <h3 class="font-weight-bold">Direcciones</h3>
            <span>
              Editar direcciones y preferencias de envío
            </span>
          </button>
        </div>
        <div class="col-md-4"  *ngIf="encuesta != undefined">
          <button class="btn btn-outline-light text-dark border-color mt-2 w-100" routerLink="/encuesta">
            <i class="fa-solid fa-square-poll-horizontal fa-2x"></i>
            <h3 class="font-weight-bold">Encuesta</h3>
            <span>
              Realizar encuesta de satisfacción
            </span>
          </button>
        </div>
        <div class="col-md-4" (click)="openModalPassword(setPassword)">
          <button class="btn btn-outline-light text-dark
           border-color mt-2 w-100">
            <i class="fas fa-lock fa-2x"></i>
              <h3 class="font-weight-bold">Seguridad</h3>
            <span>
              Editar contraseña para el inicio de sesión
            </span>

          </button>
        </div>
        <div class="col-md-4">
          <button class="btn btn-outline-danger border border-danger mt-2 w-100" (click)="abrirModalEliminarCuenta()">
            <i class="fa-solid fa-user-xmark fa-2x"></i>
            <h3 class="font-weight-bold">Eliminar cuenta</h3>
            <span>
              Eliminar perfil de usuario
            </span>

          </button>
        </div>

        <!--<div class="col-md-4">
          <button class="btn btn-outline-light text-dark
           border-color mt-2 w-100">
            <i class="fas fa-download fa-2x"></i>
            <h3 class="font-weight-bold">Descargar App</h3>
            <span>
              Descargar la aplicación para dispositivos Android
            </span>

          </button>
        </div>-->
      </div>
    </div>
  </div>
</div>


<ng-template #setPassword let-modal class="modal">
  <div class="modal-body">
    <div class="mt-2 w-100 d-flex justify-content-end">
      <div class="text-danger" (click)="modal.close()">
        <i class="fas fa-times-circle fa-2x"></i>
      </div>
    </div>

    <div class="container">
      <form (ngSubmit)="setPasswordFn(modal)" [formGroup]="passwordForm" novalidate>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field   class="w-100">
              <mat-label>Contraseña Actual</mat-label>
              <input class="w-100" [type]="hide0 ? 'password' : 'text'"
                     formControlName="oldPassword" matInput
                     (keypress)="incorrectPassword = null" (keyup)="incorrectPassword = null">
              <mat-icon matSuffix (click)="hide0 = !hide0">
                <span *ngIf="!hide0; else elseHide"><i class="fas fa-eye-slash fa-xs"></i></span>
                <ng-template #elseHide><i class="fas fa-eye fa-xs"></i></ng-template>
              </mat-icon>
              <div *ngIf="passwordForm.controls.oldPassword.touched && passwordForm.controls.oldPassword.errors?.required"
                   class="error">Campo obligatorio.
              </div>
              <div *ngIf="passwordForm.controls.oldPassword.touched &&
                                    !passwordForm.controls.oldPassword.errors?.required
                                    && passwordForm.controls.oldPassword.hasError('minlength')"
                   class="error">La contraseña debe tener mínimo cuatro caracteres.
              </div>
              <div *ngIf="passwordForm.controls.oldPassword.touched &&
                                    !passwordForm.controls.oldPassword.errors?.required
                                    && passwordForm.controls.oldPassword.hasError('maxlength')"
                   class="error">La contraseña debe tener máximo diez caracteres.
              </div>
              <div *ngIf="incorrectPassword"
                   class="error">La contraseña introducida no es correcta.
              </div>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <mat-form-field   class="w-100">
              <mat-label>Nueva Contraseña</mat-label>
              <input class="w-100" [type]="hide1 ? 'password' : 'text'"
                     formControlName="password" matInput
                     (keyup)="notSame = false">
              <mat-icon matSuffix (click)="hide1 = !hide1">
                <span *ngIf="!hide1; else elseHide"><i class="fas fa-eye-slash fa-xs"></i></span>
                <ng-template #elseHide><i class="fas fa-eye fa-xs"></i></ng-template>
              </mat-icon>
              <div *ngIf="passwordForm.controls.password.touched && passwordForm.controls.password.errors?.required"
                   class="error">Campo obligatorio.
              </div>
              <div *ngIf="passwordForm.controls.password.touched && !passwordForm.controls.password.errors?.required
                        && (passwordForm.controls.password.errors?.pattern)"
                   class="error">La contraseña debe tener mínimo 6 caracteres, una minúscula y una mayúscula.
              </div>
              <div *ngIf="passwordForm.controls.password.touched &&
                                      !passwordForm.controls.password.errors?.required
                                      && passwordForm.controls.password.errors?.validLettersIncorrect"
                   class="error">La contraseña no puede contener el carácter '
              </div>
              <div *ngIf="passwordForm.controls.password.touched &&
                                        passwordForm.controls.password.errors?.notSamePasswordIncorrect &&
                                        !passwordForm.controls.password.errors?.required"
                   class="error">Su nueva contraseña no puede coincidir con su contraseña actual.
              </div>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <mat-form-field   class="w-100">
              <mat-label>Repita la contraseña</mat-label>
              <input class="w-100" [type]="hide2 ? 'password' : 'text'"
                     formControlName="password_confirmation" matInput
                     (keyup)="notSame = false">
              <mat-icon matSuffix (click)="hide2 = !hide2">
                <span *ngIf="!hide2; else elseHide"><i class="fas fa-eye-slash fa-xs"></i></span>
                <ng-template #elseHide><i class="fas fa-eye fa-xs"></i></ng-template>
              </mat-icon>

              <div *ngIf="passwordForm.controls.password_confirmation.touched &&
                                        passwordForm.controls.password_confirmation.errors?.required"
                   class="error">Campo obligatorio.
              </div>
              <div *ngIf="passwordForm.controls.password_confirmation.touched &&
                                        passwordForm.controls.password_confirmation.errors?.samePasswordIncorrect &&
                                        !passwordForm.controls.password_confirmation.errors?.required"
                   class="error">Las contraseñas no coinciden.
              </div>
            </mat-form-field>
          </div>
        </div>
        <!-- Submit button -->
        <div class="div d-flex justify-content-center">
          <button class="btn btn-dark mr-3"
                  (click)="modal.close()">Cancelar</button>

          <button type="submit" class="btn btn-primary text-light"
                  [disabled]="!passwordForm.valid">Cambiar Contraseña</button>

        </div>
      </form>
    </div>
  </div>
</ng-template>


<modal #EliminarClienteModal>
  <modal-header><h2>Eliminar cuenta</h2></modal-header>

  <modal-content>
    <div class="container">
      <h1>¿Está seguro que desea eliminar su cuenta de usuario?</h1>
      <div class="row">
        <div class="col-md-6 col-12">
          <button type="button" class="btn btn-dark w-100 mb-2" (click)="cerrarModal()" >
            CANCELAR
          </button>
        </div>

        <div class="col-md-6 col-12">
          <button type="button" class="btn btn-danger w-100 mb-2" (click)="darDeBajaCliente()" >
            ACEPTAR
          </button>
        </div>
      </div>
    </div>
  </modal-content>
</modal>
