import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {City} from '../../../interfaces/city';
import {DefaultService} from '../../../services/default.service';
import {AuthService} from '../../../services/auth/auth.service';
import {Router} from '@angular/router';
import {Direction} from '../../../interfaces/direction';
import {Country} from "../../../interfaces/country";
import {Province} from "../../../interfaces/province";
import {CssAttributes} from '../../../interfaces/CssAttributes';

@Component({
  selector: 'app-new-direction',
  templateUrl: './new-direction.component.html',
  styleUrls: ['./new-direction.component.scss']
})
export class NewDirectionComponent implements OnInit {

  constructor(private defaultService: DefaultService, private authService: AuthService,
      private router: Router) { }

  @Input() countries: Array<Country>;
  @Input() cssAttributes: CssAttributes;
  provinces: Array<Province>;
  cities: Array<City>;
  errorDirection;
  defaultCountry = 'ES';
  defaultProvince = '28';
  defaultCity = 'default';
  defaultDirection;
  defaultPhone;
  direction: Direction;
  isPostal = false;

  msgInfoDays;
  msgInfoHour;
  days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábados', 'Domingos'];
  lettersDays = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
  userForm = new FormGroup({
  country: new FormControl('', [Validators.required]),
  province: new FormControl('', [Validators.required]),
  city: new FormControl('', [Validators.required, this.notDefaultOption()]),
  direction: new FormControl('', [Validators.required]),
  phoneNumber: new FormControl('', [Validators.pattern('^[0-9]{9}$')]),
  });

  ngOnInit(): void {
  document.documentElement.style.setProperty('--primary-color', this.cssAttributes.primary);
  document.documentElement.style.setProperty('--secondary-color', this.cssAttributes.secondary);
  document.documentElement.style.setProperty('--danger-color', this.cssAttributes.danger);
  document.documentElement.style.setProperty('--font-style', this.cssAttributes.font);
  document.documentElement.style.setProperty('--text-color', this.cssAttributes.textPrimary);
  this.initialValues();
  }

  initialValues() {
  this.direction = history.state.data;
  this.isPostal = history.state.postal;

  if (this.direction != null) {
  this.defaultCountry = this.direction.country;
  this.defaultProvince = this.direction.province;
  this.defaultCity = this.direction.city;
  this.defaultDirection = this.direction.direction;
  this.defaultPhone = this.direction.phone;
  }

  this.userForm.controls['country'].setValue(this.defaultCountry, {onlySelf: true});
  this.userForm.controls['province'].setValue(this.defaultProvince, {onlySelf: true});
  this.userForm.controls['city'].setValue(this.defaultCity, {onlySelf: true});
  this.userForm.controls['direction'].setValue(this.defaultDirection, {onlySelf: true});
  this.userForm.controls['phoneNumber'].setValue(this.defaultPhone, {onlySelf: true});
  this.getProvinces();
  }

  getProvinces() {
  const country = this.countries.find(e => e.id === this.userForm.controls['country'].value);
  if (country != null){
  this.provinces = country.provinces;
  }
  if (this.provinces.find(e => e.id === '28') != null) {
  this.userForm.controls['province'].setValue('28', {onlySelf: true});
  } else {
  this.userForm.controls['province'].setValue(this.provinces[0].id, {onlySelf: true});
  }
  this.getCities();
  }

  getCitiesDefault() {
  this.userForm.controls['city'].setValue('default', {onlySelf: true});
  this.getCities();
  }

  getCities() {
  const province = this.provinces.find(e => e.id === this.userForm.controls['province'].value);
  if (province != null){
  this.cities = province.cities;
  }
  this.msgInfoDays = null;
  }

  setMsg() {
  const id = this.userForm.controls['city'].value;
  this.msgInfoDays = null;
  if (id !== 'default') {
  const city = this.cities.filter(x => x.id === id);

  if (city.length === 1) {
    const selectDays = city[0].deliveryDays.split(',');
    let daysText = '';
    for (let i = 0; i < selectDays.length; i++) {
    const pos = this.lettersDays.findIndex(element => element === selectDays[i]);
    daysText += this.days[pos] + ', ';
    }
    this.msgInfoDays = 'Días de entrega:<b> ' + daysText.slice(0, -2) +
    '</b><br/>Horario de entrega: <b>' + city[0].deliverySchedule + '</b><br/><br/>';
  } else {
    this.msgInfoDays = '';
    city.forEach(e => {
    const selectDays = e.deliveryDays.split(',');
    let daysText = '';
    for (let i = 0; i < selectDays.length; i++) {
    const pos = this.lettersDays.findIndex(element => element === selectDays[i]);
    daysText += this.days[pos] + ', ';
    }
    this.msgInfoDays += 'Días de entrega:<b> ' + daysText.slice(0, -2) +
    '</b><br/>Horario de entrega: <b>' + e.deliverySchedule + '</b><br/><br/>';
    this.msgInfoHour = null;
    });
  }
  }
  }

  addDirection() {
  if (history.state.data == null) {
  this.errorDirection = null;
  if (this.userForm.controls['phoneNumber'].value === '') {
    this.userForm.controls['phoneNumber'].setValue(null, {onlySelf: true});
  }

  this.authService.newDirection(this.userForm.controls['country'].value, this.userForm.controls['province'].value,
    this.userForm.controls['city'].value,
    this.userForm.controls['direction'].value, this.userForm.controls['phoneNumber'].value).subscribe(
    data => {
    this.redirectTo('/miPerfil/direcciones');
    },
    err => {
    if (err.error.error === 'La dirección introducida ya existe.') {
    this.errorDirection = 'La dirección introducida ya existe.';
    }
    }
  );
  } else {
  this.updateDirection();
  }
  }

  updateDirection() {
  if (history.state.postal === true) { // EDITAR DIRECCIÓN POSTAL
  this.authService.updatePostalDirection(this.userForm.controls['country'].value, this.userForm.controls['province'].value,
    this.userForm.controls['city'].value,
    this.userForm.controls['direction'].value).subscribe(
    data => {
    this.redirectTo('/miPerfil/direcciones');
    }
  );
  } else { // EDITAR OTRA DIRECCIÓN
  this.authService.updateDirection(this.direction.country, this.direction.province, this.direction.city,
    this.direction.direction, this.userForm.controls['country'].value, this.userForm.controls['province'].value,
    this.userForm.controls['city'].value,
    this.userForm.controls['direction'].value,
    this.userForm.controls['phoneNumber'].value).subscribe(
    data => {
    this.redirectTo('/miPerfil/direcciones');
    }
  );
  }
  }

  redirectTo(uri: string) {
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
  this.router.navigate([uri]));
  //  [state]="{ data: article}"
  }

  notDefaultOption(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
  if (this.userForm != null) {
    if (control.value !== 'default') {
    return null;
    }
  }
  return {'defaultOption': {value: control.value}};
  };
  }

  changeDirection(): boolean {
  return (this.userForm.controls['country'].value === this.direction.country &&
  this.userForm.controls['province'].value === this.direction.province &&
  this.userForm.controls['city'].value === this.direction.city &&
  this.userForm.controls['direction'].value === this.direction.direction &&
  this.userForm.controls['phoneNumber'].value === this.direction.phone);
  }
}
