import {Component, OnInit} from '@angular/core';
import {AuthStorageService} from '../../services/auth/auth-storage.service';
import {WebEstaticaService} from '../../services/web-estatica/web-estatica.service';

@Component({
  selector: 'app-tpv-result',
  templateUrl: './tpv-result.component.html',
  styleUrls: ['./tpv-result.component.scss']
})
export class TpvResultComponent implements OnInit {
  isUser = false;
  constructor(private authStorageService: AuthStorageService, private webEstaticaService: WebEstaticaService) { }

  encuesta: any | undefined;
  ngOnInit(): void {
  this.isUser = this.authStorageService.isUser();

  this.webEstaticaService.getEncuestaJSON().subscribe(data => {
  this.encuesta = data;
  });
  }
}
