import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Article, ArticleCookie, ProductArticles} from '../../../interfaces/article';
import {DefaultService} from '../../../services/default.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SuperGroup} from '../../../interfaces/group';
import {Family} from '../../../interfaces/family';
import {CompanyConf} from '../../../interfaces/company-conf';
import {PriceRoute} from '../../../interfaces/price-route';
import {LocalStorageService} from '../../../services/local-storage.service';
import {ShoppingCarService} from '../../../services/shopping-car.service';
import {cloneDeep} from 'lodash';
import {NumberInterface} from '../../../interfaces/numberInterface';
import {CarArticle} from '../../../interfaces/car-article';
import {DOCUMENT} from '@angular/common';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit, AfterViewInit, OnChanges {

  constructor(private route: ActivatedRoute, private defaultService: DefaultService,
              private modalService: NgbModal,
              private router: Router, private localStorage: LocalStorageService,
              private shoppingCarService: ShoppingCarService, private http: HttpClient) {
  }

  inputArticles: Array<Article>;
  superGroups: Array<SuperGroup>;
  inputFamilies: Array<Family>;
  minPrice: number;
  companyConf: CompanyConf;
  inputProductsArticles: Array<ProductArticles>;
  paymentMethod: string;
  priceRoute: PriceRoute;
  selectKgs;
  selectUnits;
  navigationRoute;
  indicateLumps;
  isUser;
  startTopArticles;
  loadingNewArticles: boolean;
  homeDelivery: boolean;
  numberArticles: Function;

  @ViewChild('ARTICLES') templateArticles: ElementRef;
  @ViewChild('CONTAINER_ARTICLES') templateContainerArticles: ElementRef;
  isOpenSearch = false;
  productArticles: ProductArticles;

  families: Array<Family> = [];
  superGroup: SuperGroup;
  articles: Array<Article> = [];
  articlesCopy: Array<Article>  = [];
  formatNames: Array<string> = [];
  pageArticles = 1;
  numberArticlesPage = 40;
  numberArticlesPageCopy = 40;
  numberPage = 1;
  groupId: string;
  superGroupId: string;
  searchArticles = '';
  maxPrice = 0;
  offers = false;
  showOffersFilter = false;
  searchWindow = false;

  // Price options
  readyArticles = false;
  value = 0;
  highValue = 2;
  options;

  // Filter
  filterFormat = [];
  filterFamilies = [];
  filterOffer = false;
  order = 'name:asc';
  showFamilies = false;
  showFormats = false;
  showFilterMobil = false;
  familiesOccult = true;
  groupName;
  dropDownGauge = false;

  searchDefaultText = 'Buscar en ofertas';
  routeStr: string;
  innerWidth = 0;

  verTresArticulosOrdenador = false;
  numeroFotos = [];
  posicionesFotoSeleccionada = [];

  @ViewChild('SEARCH_PRODUCTS') templateSearch: TemplateRef<any>;
  viewHasInit = false;
  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  ngAfterViewInit(): void {
    this.viewHasInit = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loadingNewArticles)  {
      this.loadingNewArticles = changes.loadingNewArticles.currentValue;
    }
  }

  urlImg(pos: number, url: string): string {
    if (this.numeroFotos[pos] > 1) {
      if (this.posicionesFotoSeleccionada[pos] != 0) {
        return this.unirCadenasDespuesDelUltimoPunto(url, '_' + this.posicionesFotoSeleccionada[pos]) ;
      }

      return this.replaceLastUnderscoreWithEmpty(url) ;
    }

    return this.replaceLastUnderscoreWithEmpty(url) ;
  }

  replaceLastUnderscoreWithEmpty(inputString: string): string {
    const regex = /_(\d+)(?=[^_]*$)/;
    return inputString.replace(regex, '');
  }

  async getNumberOfPhotos() { //  imgUrl: string
    if (this.companyConf.companyException == "ENJA") {
      let pos = 0;
      for (const el of this.articles) {
        this.numeroFotos.push(0);
        this.posicionesFotoSeleccionada.push(0);
        if (el.articleImgUrl != null) {
          let existe = true;
          let int = 0;
          while (existe) {
            if (int == 0) {
              existe = await this.checkIfResourceExists('assets/fotos/articulos/' + el.articleImgUrl);
              this.numeroFotos[pos] = 1;
            } else {
              existe = false;
              let img = cloneDeep(el.articleImgUrl);
              const resultado = this.unirCadenasDespuesDelUltimoPunto(img, '_' + int);
              existe = await this.checkIfResourceExists('assets/fotos/articulos/' + resultado);
              if (existe) {
                this.numeroFotos[pos] += 1;
              }
            }

            int++;
          }
        }

        pos++;
      }
    }
  }

  unirCadenasDespuesDelUltimoPunto(cadena1: string, cadena2: string): string {
    const ultimoPuntoIndex = cadena1.lastIndexOf('.');

    if (ultimoPuntoIndex !== -1) {
      const parteAntesDelUltimoPunto = cadena1.substring(0, ultimoPuntoIndex);

      const parteDespuesDelUltimoPunto = cadena1.substring(ultimoPuntoIndex);

      const resultado = parteAntesDelUltimoPunto  + cadena2 + parteDespuesDelUltimoPunto;

      return resultado;
    } else {
      return cadena1 + cadena2;
    }
  }

  arrayFromNumber(limit: number): number[] {
    return Array.from({ length: limit }, (_, index) => index);
  }

  async checkIfResourceExists(url: string): Promise<boolean> {
    try {
      const response = await this.http.head(url).toPromise();
      return true;
    } catch (error) {
      return false;
    }
  }

  newNavigationFn() {
    this.navigationRoute = null;
    this.searchArticles = '';
    this.families = [];
    this.numberArticlesPage = 40;
    this.numberArticlesPageCopy = 40;
    this.numberPage = 1;
    this.pageArticles = 1;
    this.maxPrice = 0;
    this.showFamilies = false;
    this.showFormats = false;
    this.showFilterMobil = false;
    this.readyArticles = false;
    this.offers = false;
    this.searchWindow = false;

    this.formatNames = [];
    this.articles = null;
    this.articlesCopy = null;

    this.cargarUrlDatos();
  }

  defaultTextSearch() {
    if (this.offers) {
      this.searchDefaultText = 'Buscar en ofertas';
    } else if (this.searchWindow) {
      this.searchDefaultText = 'Buscar en todos los productos';
    } else if (this.superGroup) {
      if ((this.groupId !== '*' && this.superGroup.groups.length > 1) || this.superGroupId === 'none') {
        this.searchDefaultText = 'Buscar en ' + this.groupName;
      } else {
        this.searchDefaultText = 'Buscar en ' + this.superGroup.name;
      }
    }
  }

  cargarUrlDatos() {
    if (this.routeStr === '/compra' || this.routeStr === '/compra/ofertas') {
      this.offers = true;

      this.cargarDatos();
    } else if (this.routeStr === '/compra/buscar') {
      this.searchWindow = true;

      this.cargarDatos();
    } else {
      this.route.queryParams.subscribe(params => {
        let superGroupId = params['superGrupo'];
        if (superGroupId == '') {
          this.superGroupId = 'none';
        } else {
          this.superGroupId = superGroupId;
        }

        this.groupId = params['grupo'];

        this.cargarDatos();
      });
    }

    this.getNumberOfPhotos();
  }

  cargarDatos() {
    this.getFamilies();
    this.getArticles();
    this.defaultTextSearch();


    if (this.searchWindow && !this.isOpenSearch && this.viewHasInit) {
      this.isOpenSearch = true;
      this.modalService.open(this.templateSearch, {backdrop: 'static', centered: true});
    }

  }

  getFamilies() {
    this.inputFamilies.forEach( family => {
      if ((family.group === this.groupId && family.group !== '*') ||
        (family.superGroup === this.superGroupId && this.groupId === '*')) {
        this.families.push(family);
      }
    });
    this.families.sort((a, b) => a.name.localeCompare(b.name));
  }

  getArticles() {
    this.superGroup = null;
    this.groupName = null;
    let superGroupId = '';
    if (this.superGroupId !== 'none') {
      superGroupId = this.superGroupId;
    }

    let pos = this.superGroups.findIndex(e => e.id === superGroupId);
    if (pos != -1) {
      this.superGroup = this.superGroups[pos];
    }

    if (this.superGroupId != null && this.superGroup != null) {
      this.superGroup.groups.find(el => {
        if (el.id === this.groupId) {
          this.groupName = el.name;
        }
      });
    }

    // Filtramos los articulos
    let articles: Array<Article> = [];

    if (this.offers) {
      this.inputArticles.forEach(element => {
        if (element.offer === 1) {
          articles.push(element);
        }
      });
    } else if (this.searchWindow) {
      articles = this.inputArticles;
      try {
        document.getElementById('article-search-mobil').focus();
      } catch (e) {
        try {
          document.getElementById('article-search-computer').focus();
        } catch (e) { }
      }
    } else if (this.superGroupId === 'none') {
      this.inputArticles.forEach(element => {
        if (element.group === this.groupId && element.superGroup === '') {
          articles.push(element);
        }
      });
      this.familiesOccult = this.inputFamilies.filter((v) => (v.group === this.groupId && v.superGroup === '')).length > 1;
    } else if (this.groupId === '*') {
      this.inputArticles.forEach(element => {
        if (element.superGroup === this.superGroupId) {
          articles.push(element);
        }
      });

      this.familiesOccult = this.inputFamilies.filter((v) => (v.superGroup === this.superGroupId)).length > 1;
    } else {
      this.inputArticles.forEach(element => {
        if (element.group === this.groupId && element.superGroup === this.superGroupId) {
          articles.push(element);
        }
      });
      this.familiesOccult = this.inputFamilies.filter((v) => (v.group === this.groupId && v.superGroup === this.superGroupId)).length > 1;
    }

    for (let i = 0; i < articles.length; i++) {
      if (this.formatNames.find(e => e === articles[i].realFormatName ) == null) {
        this.formatNames.push(articles[i].realFormatName);
      }
    }
    this.articles = articles;
    this.articlesCopy = articles;

    this.showOffersFilter = this.articles.findIndex(el => el.offer) != -1;
    this.filter();


    if (this.articles.length < this.numberArticlesPage) {
      this.numberArticlesPage = this.articles.length;
      this.numberArticlesPageCopy = this.articles.length;
    } else {
      this.numberPage = Math.ceil(this.articles.length / 15);
    }
    this.highValue = this.maxPrice;
    this.options = {
      floor: 0,
      ceil: this.maxPrice,
      step: 1
    };
    this.readyArticles = true;
  }

  pageChanged(event) {
    this.pageArticles = event;
    if (this.pageArticles === this.numberPage) {
      this.numberArticlesPageCopy = this.articles.length - (this.numberPage - 1) * this.numberArticlesPage;
    } else {
      this.numberArticlesPageCopy = this.numberArticlesPage;
    }
  }

  search() {
    this.pageArticles = 1;
    this.articles = this.articlesCopy.filter(element => element.id.toLowerCase().match(this.searchArticles.toLowerCase())
      || element.name.toLowerCase().match(this.searchArticles.toLowerCase()));
  }

  open(content, article) {
    if (article.id.includes('PRODUCT-')) {
      this.productArticles = this.inputProductsArticles.find(e => e.id === article.id.replace('PRODUCT-', ''));
      this.dropDownGauge = true;
    } else {
      this.dropDownGauge = false;
      if (this.inputProductsArticles != null) {
        this.productArticles = this.inputProductsArticles.find(e => e.articles.find(a => a.id === article.id));
      }
    }
    this.modalService.open(content, {backdrop: 'static', centered: true, size: 'lg'});
  }

  // _________________________ REDIRECCIONAR _____________________________
  redirectToGrupo(nombre: string, superGrupo: string, grupo: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate([
        '/compra/' + nombre.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('')
      ], {

        queryParams: { superGrupo: superGrupo, grupo: grupo }
      }));
  }

  redirectToArticulo(nombreGrupo: string, nombreArticulo: string, grupo: string, familia: string, id: string) {
    nombreArticulo = nombreArticulo.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('');
    nombreGrupo = nombreGrupo.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('');
    this.router.navigate([
      '/compra/' + nombreGrupo + '/' + nombreArticulo
    ], {
      queryParams: { grupo: grupo, familia: familia, id: id }
    });
  }

  // _________________________ FILTROS _____________________________
  filterFormatFn(arg) {
    const format = arg.target.value;
    if (this.filterFormat.find(e => e === format) == null) {
      this.filterFormat.push(format);
    } else {
      this.filterFormat = this.filterFormat.filter(element => element !== format);
    }
    this.filter();
  }

  filterFamilyFn(arg) {
    const family = arg.target.value;
    if (this.filterFamilies.find(e => e === family) == null) {
      this.filterFamilies.push(family);
    } else {
      this.filterFamilies = this.filterFamilies.filter(element => element !== family);
    }
    this.filter();
  }

  filterOfferFn() {
    this.filterOffer = !this.filterOffer;
    this.filter();
  }

  orderArticles(id) {
    this.order = id.value;
    this.filter();
  }

  filter() {
    this.pageArticles = 1;
    let articles1: Array<Article> = [];
    if (this.filterFormat.length === 0) {
      articles1 = this.articlesCopy;
    } else {
      for (const format of this.filterFormat) {
        for (const article of this.articlesCopy) {
          if (article.realFormatName === format) {
            articles1.push(article);
          }
        }
      }
    }

    let articles2: Array<Article> = [];
    if (this.filterFamilies.length === 0) {
      articles2 = articles1;
    } else {
      for (const family of this.filterFamilies) {
        for (const article of articles1) {
          if (article.family === family) {
            articles2.push(article);
          }
        }
      }
    }

    let articles3: Array<Article>;
    if (this.filterOffer) {
      articles3 = articles2.filter(e => e.offer === 1);
    } else {
      articles3 = articles2;
    }

    if (this.order === 'price:asc') { // mas baratos primero
      articles3.sort(function(a, b) {return a.price - b.price;});
    } else if (this.order === 'price:desc') {
      articles3.sort(function(a, b) {return a.price - b.price;});
      articles3.reverse();
    } else if (this.order === 'name:asc') {
      articles3.sort((a, b) => a.name.localeCompare(b.name));
    } else if (this.order === 'name:desc') {
      articles3.sort((a, b) => a.name.localeCompare(b.name));
      articles3.reverse();
    }

    this.articles = articles3;
  }

  showFamiliesFn() {
    this.showFamilies = !this.showFamilies;
  }

  showFormatsFn() {
    this.showFormats = !this.showFormats;
  }

  showFilterMobilFn() {
    this.showFilterMobil = !this.showFilterMobil;
  }

  familySelected(arg): boolean {
    return this.filterFamilies.find(e => e === arg) != null;
  }

  formatSelected(arg): boolean {
    return this.filterFormat.find(e => e === arg) != null;
  }

  stopPropagation(e) {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  newArticleNumber(number) {
    this.numberArticles(number);
  }

  addQuantity(format: number, article: Article) {
    if (article.quantity == undefined) {
      article.quantity = 0;
    }

    if (format == 1) { // Kilos
      const pos = this.selectKgs.findIndex(kg => kg > article.quantity);
      if (pos != -1) {
        article.quantity = this.selectKgs[pos];
      }
    } else {
      const pos = this.selectUnits.findIndex(ud => ud > article.quantity);
      if (pos != -1) {
        article.quantity = this.selectUnits[pos];
      }
    }
  }

  subtractQuantity(format: number, article: Article) {
    if (article.quantity == undefined) {
      article.quantity = 0;
    }

    if (format == 1) { // Kilos
      let reverseArray = cloneDeep(this.selectKgs);
      reverseArray.reverse();
      const pos = reverseArray.findIndex(kg => kg < article.quantity);
      if (pos != -1) {
        article.quantity = reverseArray[pos];
      } else {
        article.quantity = 0;
      }
    } else {
      let reverseArray = cloneDeep(this.selectUnits);
      reverseArray.reverse();
      const pos = reverseArray.findIndex(ud => ud < article.quantity);
      if (pos != -1) {
        article.quantity = reverseArray[pos];
      } else {
        article.quantity = 0;
      }
    }

  }

  setQuantity(article: Article) { // CAMBIAR
    if (this.companyConf.ponerCantidadMenuInicial) {
      if (!this.isUser) {
        this.localStorage.removeFruitArticle(article.id);
      }

      let quantity = 0;
      if (article.quantity > 0) {
        quantity = parseFloat(String(article.quantity));
      }

      const arg: ArticleCookie = new ArticleCookie(article.id, article.line, article.name, article.format,
        null, article.price, article.nameClient, article.group,
        article.family, quantity, article.lumps,
        article.totalPriceIva, article.observations, null, article.cutName);

      if ((quantity == undefined || quantity == 0) && this.isUser) {
        this.shoppingCarService.deleteArticle(article.id, article.line, '').subscribe(
          () => {
            this.defaultService.getNumberOfArticles().subscribe(
              (result: NumberInterface) => {
                this.newArticleNumber(result.value);
              }
            );
          }
        );
      } else {
        if (!this.localStorage.addLocalStorageArticle(arg) && article.line > 0) {
          this.shoppingCarService.setQuantity(article.id, article.line, parseFloat(String(article.quantity)), article.lumps, article.format).subscribe();
        } else if ( article.line == 0 && this.isUser) {
          this.shoppingCarService.addArticle(article.id, article.format, arg.quantity, arg.lumps, arg.cutType, false).subscribe(
            () => {
              this.defaultService.getNumberOfArticles().subscribe(
                (result: NumberInterface) => {
                  this.newArticleNumber(result.value);
                }
              );
            }
          );
        }
      }

      if (!this.isUser) {
        this.numberOfArticlesLocal();
      }

    }
  }

  numberOfArticlesLocal() {
    const arts = this.localStorage.getLocalStorageArticles();
    let articlesCookies: Array<CarArticle> = null;
    if (arts != null && Array.isArray(arts)) {
      articlesCookies = arts;
    } else if (arts != null) {
      articlesCookies = [arts];
    }

    if (articlesCookies != null) {
      let articlesStr = '';
      articlesCookies.forEach( e => {articlesStr += "'" + e.id + "'" + ','});
      articlesStr = articlesStr.substr(0, articlesStr.length - 1);
      if (articlesStr != '') {
        this.shoppingCarService.getPendingArticles(articlesStr, null).subscribe(
          (data: Array<CarArticle>) => {
            if (data != undefined) {
              this.newArticleNumber(data.length);
            } else {
              this.newArticleNumber(0);
            }
          }
        );
      } else {
        this.newArticleNumber(0);
      }
    } else {

      this.newArticleNumber(0);
    }

  }

  setArticleSearchFalse(): boolean {
    return false;
  }

  protected readonly name = name;
  protected readonly Array = Array;
}
