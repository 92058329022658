import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Article, ProductArticles} from '../../../interfaces/article';
import {AuthService} from '../../../services/auth/auth.service';
import {OrdersAndImgs, Order, ArticleUserOrder} from '../../../interfaces/old-order';
import {Router} from '@angular/router';
import {BasicArticle} from '../../../interfaces/article-order';
import {ShoppingCarService} from '../../../services/shopping-car.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthStorageService} from '../../../services/auth/auth-storage.service';
import {CompanyConf} from '../../../interfaces/company-conf';
import {DomSanitizer} from '@angular/platform-browser';
import {FormControl, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {SuperGroup} from '../../../interfaces/group';
import {AlbaranesService} from '../../../services/portalClientes/albaranes/albaranes.service';

declare var device: { platform: any; };
declare let cordova: any;
declare let window: any;
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnChanges {
 articles: Array<Article>;
 loadingNewArticles: boolean;
 companyConf: CompanyConf;
 inputProductsArticles: Array<ProductArticles>;
 paymentMethod: string;
 selectedOrderSuperGroup: SuperGroup;

  numberArticles: Function;
  getNewArticlesGroup: Function;
  orders: Array<Order>;


  lastClickArticle: ArticleUserOrder;
  range = new FormGroup({start: new FormControl(), end: new FormControl()});
  today = new Date();
  starDate: string | null | undefined;
  endDate: string | null | undefined;
  selectedSuperGroup = null;

  constructor(private authService: AuthService, private router: Router, private albaranesService: AlbaranesService,
      private datePipe: DatePipe, private shoppingCarService: ShoppingCarService,
      private modalService: NgbModal, private authStorageService: AuthStorageService, private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
  if (this.lastClickArticle != null) {
    this.getArticle(this.lastClickArticle);
  }
  }

  ngOnInit(): void {
  if (this.selectedOrderSuperGroup != null) {
  this.selectedSuperGroup = this.selectedOrderSuperGroup.id;
  }

  this.getUserOrders();
  }

  getUserOrders () {
  this.authService.getUserOrders().subscribe( (data: OrdersAndImgs) => this.addOrders(data));
  }

  getArticle(article: ArticleUserOrder) {
  this.lastClickArticle = article;
  if (article.unlock) {
  let art = this.articles.find( e => e.id === article.id);
  if (art == null && article.dropDownGauge) {
    let find = false, x = 0;
    while (!find && x < this.inputProductsArticles.length) {
    if (this.inputProductsArticles[x].dropDownGauge === true) {
    art = this.inputProductsArticles[x].articles.find(a => a.id === article.id);
    if (art) {
      find = true;
      art.id = 'PRODUCT-' + this.inputProductsArticles[x].id;
    }
    }
    x++;
    }

  }

  if (art != null){
    let nombreArticulo = art.name.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('');
    let nombreGrupo = art.groupName.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('');
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([
    '/compra/' + nombreGrupo + '/' + nombreArticulo
    ], {
    queryParams: { grupo: art.group, familia: art.family, id: art.id }
    }));
  } else { // El articulo todavía no ha sido cargado.
    this.getNewArticlesGroup(article.group);
  }
  }

  }

  redirectTo(uri: string, article: Article) {
  this.router.navigateByUrl('/', {state: { data: article }, skipLocationChange: true}).then(() =>
  this.router.navigate([uri]));
  }

  addOrderToShoppingCar(content, warning, order: Order) {
  const articles: Array<BasicArticle> = [];
  const modal = this.modalService.open(warning, {backdropClass: 'light-grey-backdrop', centered: true});
  order.articles.forEach(e => {
  if (e.unlock) {
    const format = this.companyConf.companiesKgFormats.find(el => el.id === e.format);
    if (e.format !== '1' && format == null) {
    e.format = '2';
    }

    if (this.companyConf.onlyUnits) {
    e.quantity = e.lumps;
    }

    articles.push(new BasicArticle(e.id, e.format, e.quantity, e.lumps, e.cutType, e.dropDownGauge));
  }
  });

  this.shoppingCarService.addArticles(articles).subscribe(
  () => {
    this.numberArticles();
    modal.close();
    this.modalService.open(content, {backdropClass: 'light-grey-backdrop', centered: true});
  }
  );
  }

  searchOrders() {
  if (this.range.controls['start']?.value != null && this.range.controls['end']?.value != null) {
  this.endDate = this.datePipe.transform(new Date(this.range.controls['end'].value).setDate((new Date(this.range.controls['end'].value)).getDate()+1), 'dd/MM/yyyy');
  this.starDate = this.datePipe.transform(new Date(this.range.controls['start'].value), 'dd/MM/yyyy');

  this.orders = [];
  this.authService.getUserOrdersByDateRange(this.starDate, this.endDate).subscribe((data: OrdersAndImgs) => this.addOrders(data));
  }
  }

  addOrders(data) {
  let orders: Array<Order> = data.orders;
  orders.forEach( order => {
  order.totalPrice = parseFloat((Math.round((order.totalPrice + Number.EPSILON) * 100) / 100).toFixed(2));
  order.articles.forEach(art => {
    if (art.quantity == 0) {
    art.quantity = art.lumps;
    } else if ((art.lumps == 0 || art.lumps == null) && this.companyConf.onlyUnits) {
    art.lumps = art.quantity;
    }

    if (this.selectedSuperGroup != null) {
    if (this.selectedOrderSuperGroup.groups.find(g => g.id == art.group) == null) {
    art.unlock = false;
    }
    }
  })
  });

  if (this.selectedSuperGroup != null) {
  orders = orders.filter(el => el.articles.find(a => this.selectedOrderSuperGroup.groups.find(g => g.id === a.group) != null ) != null);
  }
  this.orders = orders;
  }

  formatNameFn(id): string {
  const format = this.companyConf.companiesKgFormats.find(e => e.id === id.toString());
  let name = 'Unidades';
  if (format != null) {
  name = format.value;
  }
  return name;
  }

  descargarFactura(order: Order, $event: any) {
  $event.stopPropagation();
  $event.preventDefault();
  this.albaranesService.obtenerFacturaDesdePedidoPdf(order.center, order.date.replace('/', '').replace('/', ''), order.order).subscribe(
  (data: any) => {
    this.descargarFichero('factura','pdf', data);
  }, error => { }
  );
  }

  descargarFichero(nombre: string, extension: string, data: any) {
  let fechaHora = this.datePipe.transform(new Date(), 'ddMMyyyy-hhmmss');
  let filename = nombre + fechaHora  + "."  + extension;

  let mimeType = "application/" + extension + "";
  if (extension != "pdf") {
  mimeType = "text/csv";
  }

  let blob = new Blob([data], {type: mimeType});
  if (window.cordova && cordova.platformId !== "browser") {
  document.addEventListener("deviceready", function () {
    let storageLocation = "";
    switch (device.platform) {
    case "Android":
    if (extension != "pdf") {
      mimeType = "text/plain";
      filename = nombre + fechaHora + ".txt";
      blob = new Blob([data], {type: mimeType});
    }
    storageLocation = cordova.file.externalDataDirectory;
    break;

    case "iOS":
    storageLocation = cordova.file.documentsDirectory;
    break;    }

    var folderPath = storageLocation;

    window.resolveLocalFileSystemURL(
    folderPath,
    function (dir: any) {
    dir.getFile(
      filename,
      {
      create: true
      },
      function (file: any) {
      file.createWriter(
      function (fileWriter: any) {
        fileWriter.write(blob);

        fileWriter.onwriteend = function () {
        var url = file.toURL();
        cordova.plugins.fileOpener2.open(url, mimeType, {
        error: function error(err: any) {
          console.error(err);
          alert("No es posible descargar el fichero");
        },
        success: function success() {}
        });
        };

        fileWriter.onerror = function (err: any) {
        alert("No es posible descargar el fichero");
        console.error(err);
        };
      },
      function (err: any) {
        // failed
        alert("No es posible descargar el fichero");
        console.error(err);
      }
      );
      },
      function (err: any){
      alert("No es posible descargar el fichero");
      console.error(err);
      }
    );
    },
    function (err: any) {
    alert("No es posible descargar el fichero");
    console.error(err);
    }
    );
  });
  } else {
  this.descargarFicheroWeb(filename, data);
  }
  }

  descargarFicheroWeb(filename: string, data: any) {
  let downloadURL = window.URL.createObjectURL(data);
  let link = document.createElement('a');
  link.download = filename;
  link.href = downloadURL;
  link.click();
  }
}
