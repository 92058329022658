import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Inicio, SeccionArticulos} from '../../../interfaces/web-estatica/inicio';
import {Group} from '../../../interfaces/group';
import {Article} from '../../../interfaces/article';
import {CompanyConf} from '../../../interfaces/company-conf';
import {Router} from '@angular/router';
import {DefaultService} from '../../../services/default.service';

declare let cordova: any;
declare let window: any;
@Component({
  selector: 'app-inicio-web',
  templateUrl: './inicio-web.component.html',
  styleUrls: ['./inicio-web.component.scss']
})
export class InicioWebComponent implements OnInit{

  constructor(private router: Router, private _cdr: ChangeDetectorRef, private defaultService: DefaultService,) { }

  fotoSeleccionada = '1';
  inicioJson: undefined | Inicio;
  counter = 0;
  visible = true;
  groups: undefined | Array<Group>;
  articulosOferta: Array<Article>;
  secciones: Array<SeccionArticulos> = [];
  paginasCarrousel = 0;
  posicionCarrousel = 0;
  companyConf: CompanyConf | undefined;
  appMovil = true;


  losArticulosMasPedidos: Array<Article> = [];

  ngOnInit(): void {
  this.obtenerLosArticulosMasPedidos();
  }

  obtenerLosArticulosMasPedidos() {
  this.defaultService.obtenerLoMasPedidoPorTodosLosClientes().subscribe(
  (data: any) => {
    this.losArticulosMasPedidos = data;
  }
  )
  }

  nextPhoto() {
  if (!(window.cordova && cordova.platformId !== "browser")) {
  this.appMovil = false;
  }

  this.visible = !this.visible;
  if (this.counter == (this.inicioJson.fotos.length - 1)) {
  this.counter = 0;
  } else {
  this.counter += 1;
  }
  this.fotoSeleccionada = this.inicioJson.fotos[this.counter];

  this._cdr.detectChanges();
  this.sleep(7000).then(() => this.nextPhoto());
  }

  sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
  }

  openExternalLink(link: string) {
  if (window.cordova && cordova.platformId !== "browser") {
  cordova.InAppBrowser.open(link, '_system');
  } else {
  window.open(link, '_blank', 'location=yes');
  }
  }

  windowTop() {
  window.scroll(0, 0);
  }

  superGroupId(superGroup) {
  if (superGroup == "" || superGroup == undefined) {
  return "none";
  }

  return superGroup;
  }


  irARuta(ruta: string, posicion: string) {
  this.redirectTo(ruta, posicion);
  }


  // _________________________ REDIRECCIONAR _____________________________
  redirectTo(uri: string, posicion: string) {
  if (posicion != undefined && posicion != "") {
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([uri], {fragment: posicion}));
  } else {
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([uri]));
  }
  }

  redireccionarSeccion(ruta: string) {
  let grupo = ruta.split('&grupo=');
  let superGrupo = ruta.split('?superGrupo=');

  if (grupo.length > 1 && superGrupo.length > 1) {
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([
    superGrupo[0]
    ], {
    queryParams: { superGrupo: superGrupo[1].split('&grupo=')[0], grupo: grupo[1] }
    }));
  } else {
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([ruta]));
  }


  }
  redirectToGrupo(nombre: string, superGrupo: string, grupo: string) {
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
  this.router.navigate([
    '/compra/' + nombre.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('')
  ], {
    queryParams: { superGrupo: superGrupo, grupo: grupo }
  }));
  }

  redirectToArticulo(nombreGrupo: string, nombreArticulo: string, grupo: string, familia: string, id: string) {
  nombreArticulo = nombreArticulo.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('');
  nombreGrupo = nombreGrupo.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('');
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
  this.router.navigate([
    '/compra/' + nombreGrupo + '/' + nombreArticulo
  ], {
    queryParams: { grupo: grupo, familia: familia, id: id }
  }));
  }

  protected readonly console = console;
}
