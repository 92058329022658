import { Injectable } from '@angular/core';
import roundHalfEven from 'round-half-even';
import {Country} from '../interfaces/country';
import {Default} from '../interfaces/default';
@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  constructor() { }

  roundFn(companyConf, num, decimals) {
  if (companyConf.typeRound === 1) {
  return roundHalfEven(num, decimals);
  }
  return parseFloat((Math.round((num + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals)).toFixed(decimals));
  }

  deliveryDate(countries: Array<Country>, province: string, city: string, country: string): string {
  let c = countries.find(c => country == c.id);
  if (c != null) {
  let p = c.provinces.find(p => p.id == province);
  if (p != null) {
    let ci = p.cities.find(c => c.id == city);
    if (ci != null) {
    if (ci.deliveryDates.length > 0) {
    return ci.deliveryDates[0].value;
    }
    }
  }
  }

  return null;
  }
}
