<div class="d-flex success-order justify-content-center align-items-center" style="min-height: 55vh">
  <div class="d-flex justify-content-center">
  <div>
  <div class="text-primary max d-flex justify-content-center">
    <i class="fas fa-thumbs-up fa-5x"></i>
  </div>
  <div class="max d-flex justify-content-center text-center">
    <h1>Su pedido se ha procesado con éxito</h1>
  </div>
  <p *ngIf="isUser">
    Gracias por realizar tu pedido, puedes ver tus pedidos en la página de
    <a routerLink="/miPerfil">Mi perfil</a>,
    pulsado sobre
    <a routerLink="/miPerfil/misPedidos">Mis Pedidos.</a>
  </p>

  <div class="max d-flex justify-content-center text-center">
    <h3><b>Compruebe la bandeja de su correo electrónico para ver una copia de su pedido.</b></h3>
  </div>

  <div class="mt-3 text-center border-top pt-3" *ngIf="encuesta != undefined">
    <h2>¡Gracias por su compra!</h2>
    <h3>Su opinión es muy importante para nosotros.</h3>
    <h4>Cuando le llegue su pedido puede realizar una encuesta de satisfacción en su <a href="/miPerfil">area de cliente</a>.</h4>
    <!--<a class="btn btn-primary text-light" routerLink="/encuesta">
    Realizar encuesta de satisfacción
    </a>-->
  </div>
  </div>
  </div>
</div>
