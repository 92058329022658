<div class="container" style="min-height: 60vh">
  <div class="row">
  <div class="col-md-3"></div>
  <div class="col-md-6 mb-3">
  <h2>CAMBIAR CONTRASEÑA</h2>
  <form (ngSubmit)="setPasswordFn(CHANGEPASSWORD)" [formGroup]="passwordForm" novalidate>
    <div class="row">
    <div class="col-12">
    <mat-form-field class="w-100">
      <mat-label>Contraseña Actual</mat-label>
      <input [type]="hide0 ? 'password' : 'text'" class="w-100"
       formControlName="oldPassword" matInput
       (keypress)="incorrectPassword = null" (keyup)="incorrectPassword = null">
      <mat-icon matSuffix (click)="hide0 = !hide0">
      <span *ngIf="!hide0; else elseHide"><i class="fas fa-eye-slash fa-xs"></i></span>
      <ng-template #elseHide><i class="fas fa-eye fa-xs"></i></ng-template>
      </mat-icon>
      <div *ngIf="passwordForm.controls.oldPassword.touched && passwordForm.controls.oldPassword.errors?.required"
       class="error">Campo obligatorio.
      </div>
      <div *ngIf="passwordForm.controls.oldPassword.touched &&
            !passwordForm.controls.oldPassword.errors?.required
            && passwordForm.controls.oldPassword.hasError('minlength')"
       class="error">La contraseña debe tener mínimo cuatro caracteres.
      </div>
      <div *ngIf="passwordForm.controls.oldPassword.touched &&
            !passwordForm.controls.oldPassword.errors?.required
            && passwordForm.controls.oldPassword.hasError('maxlength')"
       class="error">La contraseña debe tener máximo diez caracteres.
      </div>
      <div *ngIf="incorrectPassword"
       class="error">La contraseña introducida no es correcta.
      </div>
    </mat-form-field>
    </div>
    </div>

    <div class="row">
    <div class="col-12">
    <mat-form-field class="w-100">
      <mat-label>Nueva Contraseña</mat-label>
      <input [type]="hide1 ? 'password' : 'text'" class="w-100"
       formControlName="password" matInput (keyup)="notSame = false">
      <mat-icon matSuffix (click)="hide1 = !hide1">
      <span *ngIf="!hide1; else elseHide"><i class="fas fa-eye-slash fa-xs"></i></span>
      <ng-template #elseHide><i class="fas fa-eye fa-xs"></i></ng-template>
      </mat-icon>
      <div *ngIf="passwordForm.controls.password.touched && passwordForm.controls.password.errors?.required"
       class="error">Campo obligatorio.
      </div>
      <div *ngIf="passwordForm.controls.password.touched && !passwordForm.controls.password.errors?.required
        && (passwordForm.controls.password.errors?.pattern)"
       class="error">La contraseña debe tener mínimo 6 caracteres, una minúscula y una mayúscula.
      </div>
      <div *ngIf="passwordForm.controls.password.touched &&
              !passwordForm.controls.password.errors?.required
              && passwordForm.controls.password.errors?.validLettersIncorrect"
       class="error">La contraseña no puede contener el carácter '
      </div>
      <div *ngIf="passwordForm.controls.password.touched &&
              passwordForm.controls.password.errors?.notSamePasswordIncorrect &&
              !passwordForm.controls.password.errors?.required"
       class="error">Su nueva contraseña no puede coincidir con su contraseña actual.
      </div>
    </mat-form-field>
    </div>
    </div>

    <div class="row">
    <div class="col-12">
    <mat-form-field class="w-100">
      <mat-label>Repita la contraseña</mat-label>
      <input [type]="hide2 ? 'password' : 'text'" class="w-100"
       formControlName="password_confirmation" matInput (keyup)="notSame = false">
      <mat-icon matSuffix (click)="hide2 = !hide2">
      <span *ngIf="!hide2; else elseHide"><i class="fas fa-eye-slash fa-xs"></i></span>
      <ng-template #elseHide><i class="fas fa-eye fa-xs"></i></ng-template>
      </mat-icon>

      <div *ngIf="passwordForm.controls.password_confirmation.touched &&
              passwordForm.controls.password_confirmation.errors?.required"
       class="error">Campo obligatorio.
      </div>
      <div *ngIf="passwordForm.controls.password_confirmation.touched &&
              passwordForm.controls.password_confirmation.errors?.samePasswordIncorrect &&
              !passwordForm.controls.password_confirmation.errors?.required"
       class="error">Las contraseñas no coinciden.
      </div>
    </mat-form-field>
    </div>
    </div>

    <!-- Submit button -->
    <div class="div d-flex justify-content-center">
    <button type="submit" class="btn btn-primary"
      [disabled]="!passwordForm.valid">Cambiar Contraseña</button>

    </div>
  </form>
  </div>
  <div class="col-md-3"></div>
  </div>
</div>

<ng-template #CHANGEPASSWORD let-modal class="modal">
  <div class="modal-body">
  <div class="max d-flex justify-content-end">
  <div class="text-danger" (click)="modal.close()">
    <i class="fas fa-times-circle fa-2x"></i>
  </div>
  </div>

  <div class="container w-100">
  <h3 class="text-info font-weight-bold  text-center">CONTRASEÑA CAMBIADA CON ÉXITO</h3>
  <div class="d-flex justify-content-center">
    <button class="btn btn-info mr-3" routerLink="/iniciarSesion"
      (click)="modal.close()">Aceptar</button>
    </div>
    </div>
  </div>
</ng-template>
