import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Article, ProductArticles} from '../../../interfaces/article';
import {SuperGroup} from '../../../interfaces/group';
import {Family} from '../../../interfaces/family';
import {AuthStorageService} from '../../../services/auth/auth-storage.service';
import {CompanyConf} from '../../../interfaces/company-conf';
import {PriceRoute} from "../../../interfaces/price-route";
import {ChangeDetectorRef} from '@angular/core';
@Component({
  selector: 'app-article-window',
  templateUrl: './article-window.component.html',
  styleUrls: ['./article-window.component.scss']
})
export class ArticleWindowComponent  {

  constructor(private router: Router, private route: ActivatedRoute, private cdr: ChangeDetectorRef) {
  }

  groupName: string;
  groupId: string;
  familyName: string;
  familyId: string;
  article: Article;
  productArticles: ProductArticles;
  articleId: string;
  superGroup: SuperGroup;
  navigationRoute;
  homeDelivery: boolean;
  inputArticles: Array<Article>;
  inputProductsArticles: Array<ProductArticles>;
  families: Array<Family>;
  superGroups: Array<SuperGroup>;
  minPrice: number;
  paymentMethod: string;
  companyConf: CompanyConf;
  priceRoute: PriceRoute;
  selectKgs;
  selectUnits;
  isUser: boolean;
  indicateLumps: boolean;
  numberArticles: Function;

  navigationRouteCopy = null;
  articles: Array<Article>;

  dropDownGauge = false;

  newNavigationFn(){
    this.navigationRouteCopy = this.navigationRoute;
    this.navigationRoute = null;
    this.route.queryParams.subscribe(params => {
      this.groupId = params['grupo'];
      this.familyId = params['familia'];
      this.articleId = params['id'];

      this.getArticle();

      if (this.article != null) {
        this.groupName = this.article.groupName;
        this.familyName = this.article.familyName;
      } else if (this.productArticles != null) {
        this.groupName = this.productArticles.groupName;
        this.familyName = this.productArticles.familyName;
      }

      this.getArticles();
      this.superGroups.forEach( e => e.groups.find(el => {
        if (el.id === this.groupId && e.id !== '') {
          this.superGroup = e;
        }
      }));

      this.cdr.detectChanges();
    });
  }


  newArticleNumber(number){
    this.numberArticles(number);
  }

  getArticle() {
    if (this.articleId != undefined) {
      if (this.articleId.includes('PRODUCT-')) {
        this.productArticles = this.inputProductsArticles.find(e => e.id === this.articleId.replace('PRODUCT-', ''));
        this.dropDownGauge = true;
      } else {
        this.article = this.inputArticles.find(e => e.id == this.articleId);
        if (this.companyConf.companyType == 'P') {
          this.productArticles = this.inputProductsArticles.find(e => {
            if (e.articles.findIndex(art => art.id === this.articleId) != -1) {
              return true;
            }
          });
        }
      }
    }
  }

  getArticles() {
    if (this.article) {
      this.articles = this.inputArticles.filter( e => (e.group == this.groupId && e.family == this.familyId && e.id != this.articleId));
    } else {
      // Articles data
      const articles: Array<Article> = [];
      for (const product of this.inputProductsArticles) {
        if (product.group ===  this.productArticles.group
            && product.family ===  this.productArticles.family
            && product.id !== this.productArticles.id) {
          if (product.dropDownGauge === false ) {
            product.articles.forEach(e => articles.push(e));
          } else {
            const art = new Article('PRODUCT-' + product.id , product.name, null, product.articles[0].realFormatName,
                null, product.productImgUrl, product.img,
                0, 0, product.name, product.group, product.groupName, product.family, product.familyName, 0, product.superGroup,
                1, null, null, false, 0, 0, 0, null);
            articles.push(art);
          }
        }
      }

      this.articles = articles;
    }

  }

  // _________________________ REDIRECCIONAR _____________________________
  redirectToGrupo(nombre: string, superGrupo: string, grupo: string) {
    this.router.navigateByUrl('/').then(() =>
        this.router.navigate([
          '/compra/' + nombre.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('')
        ], {
          queryParams: { superGrupo: superGrupo, grupo: grupo }
        }));
  }
}
