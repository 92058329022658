<p class="text-dark font-weight-bold font-size-title">Productos relacionados</p>
<div class="scrolling-wrapper" #CONTENT>
  <div class="pointer h-100" style="float:left; margin-right: 15px;" *ngFor="let article of articles">
    <div [style]="'min-height:' + contentHeight + 'px !important;'" (click)="getNewArticle(article)">
      <div class="card mb-2 h-100" style="width: 200px" [style]="'min-height:' + contentHeight + 'px !important;'">
        <div  *ngIf="article.articleImgUrl || article.productImgUrl">
          <img *ngIf="article.articleImgUrl; else productImg" width="100%" height="auto"
               src="./assets/fotos/articulos/{{article.articleImgUrl}}"  alt="{{article.name}}"/>
          <ng-template #productImg>
            <img *ngIf="article.productImgUrl" width="100%" height="auto"
                 src="./assets/fotos/productos/{{article.productImgUrl}}"  alt="{{article.name}}"/>
          </ng-template>
        </div>

        <div class="card-body">
          <h2 class="card-title text-dark article-name">{{article.name}}</h2>
          <p class="card-text font-size-title" [style]="'color: ' + companyConf.priceColor + ';'"
             *ngIf="(article.priceIva > 0); else NO_PRICE">
            {{article.priceIvaStr | currency: 'EUR'}}
            <span *ngIf="article.format === '1'">/Kg</span>
            <span *ngIf="article.format !== '1'">/ud.</span>
          </p>

          <ng-template #NO_PRICE><p class="card-text text-white">a</p></ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
