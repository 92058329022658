<div *ngIf="orders == null || lastClickArticle != null; else elseOrder"
   class="align-items-center d-flex justify-content-center container-load">
  <div>
  <div class="w-100 d-flex justify-content-center mb-3">
  <img src="assets/loading/loading.gif"  alt="Cargando icono" title="Icono cargando datos"
     style="height: 150px !important; width: auto !important;"/>
  </div>
  <div class="text-center">
  <h3>Estamos cargando todos los datos</h3>
  <p style="font-size: 14px">Espere un momento</p>
  </div>
  </div>
</div>

<ng-template #elseOrder>
  <div class="w-100 mb-4 d-flex justify-content-center" style="min-height: 60vh">
  <div class="container-order">
  <div class="row">
    <div class="col-md-8">
    <h3>
    <span>
      <a routerLink="/miPerfil">Mi Perfil</a> > Mis pedidos
    </span>
    </h3>
    </div>
    <div class="col-md-4">
    <mat-form-field appearance="fill" class="w-100">
    <mat-label>Buscar por fechas</mat-label>
    <mat-date-range-input  class="w-100" [formGroup]="range" [rangePicker]="picker" (click)="picker.open()"  [max]="today">
      <input class="w-100" matStartDate formControlName="start" placeholder="Fecha inicial"
       (dateChange)="searchOrders()">
      <input class="w-100" matEndDate formControlName="end" placeholder="Fecha final" readonly
       (dateChange)="searchOrders()">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    </div>
  </div>

  <div class="card" *ngFor="let order of orders">
    <div class="card-header">
    <div class="row">
    <div class="col-md-10">
      <div class="row w-100 computer">
      <div class="col-md-6 mb-3 pointer">
      <div class="d-flex" (click)="order.show = !order.show">
        <div class="mr-3 d-flex align-items-center" >
        <i class="fas fa-caret-down mr-2" *ngIf="order.show"></i>
        <i class="fas fa-caret-up mr-2" *ngIf="!order.show"></i>
        </div>
        <div class="mr-3" style="font-size: 0.875em; width: 50% !important;">
        PEDIDO REALIZADO<br/><span class="font-weight-bold">{{order.date}}</span>
        </div>
        <div style="font-size: 0.875em; width: 50% !important;">
        ENTREGA EL DÍA<br/><span class="font-weight-bold">{{order.deliveryDate}}</span>
        </div>
      </div>
      </div>
      <div class="col-md-6 mb-3" (click)="order.show = !order.show">
      <div class="d-flex">
        <div class="mr-3" style="font-size: 0.875em; width: 50% !important;"
         *ngIf="order.totalTransport > 0">
        TRANSPORTE<br/>
        <span class="font-weight-bold">
        {{(order.totalTransport| currency: 'EUR')}}
        </span>
        </div>

        <div style="font-size: 0.875em; width: 50% !important;">
        TOTAL (Imp. incluidos)<br/>
        <span class="font-weight-bold" [style]="'color: ' + companyConf.priceColor + ';'">
        {{(order.totalPrice| currency: 'EUR')}}
        </span>
        </div>
        <button class="btn btn-outline-dark" *ngIf="order.existeAlbaran" (click)="descargarFactura(order, $event);">
        Descargar
        </button>
      </div>

      </div>

      </div>
      <div class="w-100 mobil" style="font-size: 0.875em;" >
      <div class="row pointer" (click)="order.show = !order.show">
      <div class="col-2 h-100">
        <div class="d-flex h-100 align-items-center">
        <i class="fas fa-caret-down fa-2x mr-2" *ngIf="order.show"></i>
        <i class="fas fa-caret-up fa-2x mr-2" *ngIf="!order.show"></i>
        </div>
      </div>
      <div class="col-10">
        Pedido realizado: <span class="font-weight-bold">{{order.date}}</span><br/>
        Día de entrega: <span class="font-weight-bold">{{order.deliveryDate}}</span><br/>
        <span *ngIf="order.totalTransport > 0">
          Precio transporte
                    <span class="font-weight-bold">
                        {{(order.totalTransport| currency: 'EUR')}}
                      </span><br/>
                    </span>

                    <span>
                      Total (Imp. incluidos)
                      <span class="font-weight-bold" [style]="'color: ' + companyConf.priceColor + ';'">
                        {{(order.totalPrice| currency: 'EUR')}}
                      </span><br/>
                    </span>


                    <button class="btn btn-outline-dark" *ngIf="order.existeAlbaran" (click)="descargarFactura(order, $event);">
                      Descargar
                    </button>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-md mt-3 mobil" *ngIf="order.permitNewOrder == true">
              <div class="d-flex justify-content-center w-100">
                <button class="btn btn-primary text-light" style="font-size: 13px"
                        (click)="addOrderToShoppingCar(AddOrder,AddingOrder, order)">
                  Añadir al carrito
                </button>
              </div>
            </div>

            <div class="col-md mt-3 computer" *ngIf="order.permitNewOrder == true">
              <div class="d-flex justify-content-end w-100">
                <button class="btn btn-primary text-light" style="font-size: 13px"
                        (click)="addOrderToShoppingCar(AddOrder,AddingOrder, order)">
                  Añadir al carrito
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="order.show">
          <div *ngFor="let article of order.articles" class="border-dark-50 border-bottom ">
            <div class="container-article">
              <div class="row">
                <div class="col-12 d-flex w-100"  (click)="getArticle(article)"
                     [ngClass]="{'col-8': !(article.articleImgUrl || article.productImgUrl)}" *ngIf="article.unlock">
                  <div *ngIf="article.articleImgUrl || article.productImgUrl" class="ml-2 d-flex justify-content-start mb-1 mt-1" >
                    <div>
                      <img *ngIf="article.articleImgUrl; else productImg"
                           class="img"
                           src="./assets/fotos/articulos/{{article.articleImgUrl}}"  alt="{{article.name}}"/>
                      <ng-template #productImg>
                        <img *ngIf="article.productImgUrl"
                             class="img"
                             src="./assets/fotos/productos/{{article.productImgUrl}}"  alt="{{article.name}}"/>
                      </ng-template>

                      <div class="w-100 d-flex justify-content-end quantity" style="margin-top: -25px">
                        <ng-container *ngIf="article.format === '1' && article.quantityGauge == 0; else elseQuantity1">
                          {{(article.quantity| number : '1.2-2').replace('.',',')}} Kilos
                        </ng-container>
                        <ng-template #elseQuantity1>
                          <ng-container *ngIf="article.format !== '1' && article.quantityGauge == 0; else elseQuantity2">
                            {{article.quantity}} {{formatNameFn(article.format)}}
                          </ng-container>
                        </ng-template>
                        <ng-template #elseQuantity2>
                          {{article.quantityGauge}} {{formatNameFn(article.format)}}
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="p-1 ml-2 w-100">
                    <div class="d-flex w-100">
                      <div class="w-100">
                        <h4 class="text-dark mb-0 article-name mw-100" id="name">
                          <span class="w-100" *ngIf="article.nameClient != null">{{article.nameClient}}</span>
                          <span class="w-100" *ngIf="article.nameClient == null">{{article.name}}</span>
                          <span class="w-100" *ngIf="article.dropDownGauge">
                          <br/>Tamaño: <span class="font-weight-light">{{article.gaugeName}}</span>
                        </span>
                          <span class="mw-100 w-100" *ngIf="article.cutType">
                          <br/>Presentación: <span class="font-weight-light">{{article.cutName}}</span>
                        </span>
                          <span class="w-100" *ngIf="article.lumps > 0">
                          <br/>Número de unidades: <span class="font-weight-light">{{article.lumps}}</span>
                        </span>
                        </h4>

                        <span *ngIf="article.articleImgUrl || article.productImgUrl; else elseQuantity" class="margin-right font-weight-light d-flex mb-1"
                              [style]="'color: ' + companyConf.priceColor" style="font-size: 15px;">
                        <div>
                          {{(article.price| currency: 'EUR')}}
                        </div>
                      </span>

                        <ng-template #elseQuantity>
                          <div class="d-flex w-100">
                            <div>
                              {{(article.price| currency: 'EUR')}}
                            </div>
                            <div *ngIf="!(article.articleImgUrl || article.productImgUrl)" class="ml-5">
                              <div class="pr-3 text-dark font-weight-bold">
                                <ng-container *ngIf="article.format === '1' && article.quantityGauge == 0; else elseQuantity1">
                                  {{(article.quantity| number : '1.2-2').replace('.',',')}} Kilos
                                </ng-container>
                                <ng-template #elseQuantity1>
                                  <ng-container *ngIf="article.format !== '1' && article.quantityGauge == 0; else elseQuantity2">
                                    {{article.quantity}} {{formatNameFn(article.format)}}
                                  </ng-container>
                                </ng-template>
                                <ng-template #elseQuantity2>
                                  {{article.quantityGauge}} {{formatNameFn(article.format)}}
                                </ng-template>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- ARTICULOS NO DISPONIBLES -->
                <div class="col-12 d-flex mb-3" *ngIf="!article.unlock">
                  <div class="ml-4 h-100 mr-2 " >
                    <div class="d-flex justify-content-start" style="min-width: 110px">
                      <div class="text-center">
                        <h4 class="font-weight-bold mb-0">
                          <span class="mb-0 p-0">Artículo no </span><span>disponible</span>
                        </h4>
                      </div>
                    </div>

                    <div class="d-flex justify-content-end">
                      <ng-container *ngIf="article.format === '1' && article.quantityGauge == 0; else elseQuantity1">
                        {{(article.quantity| number : '1.2-2').replace('.',',')}} Kilos
                      </ng-container>
                      <ng-template #elseQuantity1>
                        <ng-container *ngIf="article.format !== '1' && article.quantityGauge == 0; else elseQuantity2">
                          {{article.quantity}} {{formatNameFn(article.format)}}
                        </ng-container>
                      </ng-template>
                      <ng-template #elseQuantity2>
                        {{article.quantityGauge}} {{formatNameFn(article.format)}}
                      </ng-template>
                    </div>
                  </div>

                  <div class="ml-3">
                    <h4 class="text-black-50 font-weight-bold mb-0">{{article.name}}</h4>
                    <span class="margin-right font-weight-light" [style]="'color: ' + companyConf.priceColor + '; font-size: 15px;'">
                    {{(article.price| currency: 'EUR')}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #AddingOrder let-modal class="modal">
  <div class="modal-body">
    <div class="container">
      <div class="d-flex mb-2 w-100">
        <div class="d-flex justify-content-end w-100">
          <a class="text-danger" (click)="modal.close()">
            <i class="fas fa-times-circle fa-2x"></i>
          </a>
        </div>
      </div>

      <div class="w-100 font-weight-bold w-100 text-center">
        <h1 class="text-dark">Añadiendo pedido al carrito.</h1>
        <div class="w-100 d-flex justify-content-center mb-3">
          <img src="assets/loading/loading.gif"  alt="Cargando icono" title="Icono cargando datos" style="height: 150px !important; width: auto !important;"/>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #AddOrder let-modal class="modal">
  <div class="modal-body">
    <div class="container">
      <div class="d-flex mb-2 w-100">
        <div class="d-flex justify-content-end w-100">
          <a class="text-danger" (click)="modal.close()">
            <i class="fas fa-times-circle fa-2x"></i>
          </a>
        </div>
      </div>

      <div class="w-100 font-weight-bold w-100 text-center">
        <h1 class="text-dark">Pedido añadido correctamente al carrito.</h1>
      </div>

      <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-primary text-light  btn-modal"
                routerLink="/carrito" (click)="modal.close()">
          Ir al carrito
        </button>
      </div>
    </div>
  </div>
</ng-template>
