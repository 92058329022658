<div *ngIf="innerWidth < 990">
  <div class="d-flex justify-content-center mw-100 pt-1 " #container>
  <div class="mh-100 d-flex align-items-center" #left *ngIf="showArrow(list, container)"
   (click)="scrollLeft(list)" style="width: 4% !important;">
  <i class="fas fa-chevron-circle-left" ></i>
  </div>
  <div class="scrolling-wrapper p-0 m-0" #list style="width: 92% !important;">
  <div *ngFor="let superGroup of superGroups" class="d-flex">
    <div *ngFor="let group of superGroup.groups" (click)="getArticlesGroup(superGroup, group)">
    <div *ngIf="group.id == 'of'; else GRUPO_T">
    <div class="container-img center">
      <img src="./assets/fotos/grupos/ofertas.webp" alt="Ofertas" class="img"/>
    </div>

    <div class="tipo-nombre">{{group.name}}</div>
    </div>

    <ng-template #GRUPO_T>
    <div class="tipo-comida" *ngIf="group.id !== ''">
      <div class="container-img center">
      <img *ngIf="group.groupImgUrl"
       src="./assets/fotos/grupos/{{group.groupImgUrl}}" alt="Imagen {{group.name}}" class="img"/>
      </div>

      <div class="tipo-nombre">{{group.name}}</div>
    </div>
    </ng-template>
    </div>
  </div>
  </div>

  <div class="mh-100 d-flex align-items-center"
   *ngIf="showArrow(list, container)"
   (click)="scrollRight(list)" style="width: 4% !important;">
  <i class="fas fa-chevron-circle-right "></i>
  </div>
  </div>
</div>

<div *ngIf="innerWidth > 990">
  <div class="d-flex justify-content-center w-100 pt-1 " #container>
  <div class="mh-100 d-flex align-items-center" #left *ngIf="showArrow(list, container)" style="width: 4% !important;">
  <i class="fas fa-chevron-circle-left" ></i>
  </div>
  <div class="p-0 m-0" #list style="width: 92% !important;">
  <div *ngFor="let superGroup of superGroups" class="d-flex justify-content-center">
    <div *ngFor="let group of superGroup.groups" (click)="getArticlesGroup(superGroup, group)">
    <div *ngIf="group.id == 'of'; else GRUPO_T">
    <div class="center">
      <img src="./assets/fotos/grupos/ofertas.webp" alt="Ofertas"
      width="200px" height="auto"/>
    </div>
    </div>

    <ng-template #GRUPO_T>
    <div style="margin-right: 5px; display: inline-block;" *ngIf="group.id !== ''">
      <div class="center" >
      <img *ngIf="group.groupImgUrl"
       src="./assets/fotos/grupos/{{group.groupImgUrl}}"
       width="120px" height="auto"
       alt="Imagen {{group.name}}" class="img-computer"/>
      </div>
    </div>
    </ng-template>
    </div>
  </div>
  </div>

  <div class="mh-100 d-flex align-items-center"
   *ngIf="showArrow(list, container)"
   (click)="scrollRight(list)" style="width: 4% !important;">
  <i class="fas fa-chevron-circle-right "></i>
  </div>
  </div>
</div>
