import {Component, Input, Output, EventEmitter,  OnInit} from '@angular/core';
import {Article, ArticleCookie, ProductArticles} from '../../../interfaces/article';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {AuthStorageService} from '../../../services/auth/auth-storage.service';
import {ShoppingCarService} from '../../../services/shopping-car.service';
import {SummaryOrder} from '../../../interfaces/summary-order';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CompanyConf} from '../../../interfaces/company-conf';
import {Router} from '@angular/router';
import {PriceRoute} from "../../../interfaces/price-route";
import {LocalStorageService} from "../../../services/local-storage.service";
import {NumberInterface} from "../../../interfaces/numberInterface";
import {FunctionsService} from '../../../services/functions.service';
import {cloneDeep} from 'lodash';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})

export class ArticleComponent {

  constructor(private authStorageService: AuthStorageService, private shoppingCarService: ShoppingCarService,
              private modalService: NgbModal,private http: HttpClient, private router: Router,
              private localStorage: LocalStorageService, private functionsService: FunctionsService) {}

  @Input() navigationRoute;
  @Input() article: Article;
  @Output() closeWindow: EventEmitter<boolean> = new EventEmitter();
  @Input() minPrice: number;
  @Input() companyConf: CompanyConf;
  @Input() productArticles: ProductArticles;
  @Input() dropDownGauge: boolean;
  @Input() paymentMethod: string;
  @Input() inputProductsArticles: Array<ProductArticles>;
  @Input() priceRoute: PriceRoute;
  @Input() selectKgsOriginal;
  @Input() selectUnitsOriginal;
  @Input() isUser: boolean;
  @Output() numberArticles = new EventEmitter<number>();
  @Input() indicateLumps: boolean;
  @Input() articleId: string;
  @Input() homeDelivery: boolean;

  selectedArticle: string;
  totalPrice = 0;
  totalTransport = 0;

  selectKgs;
  selectUnits;
  quantityUdForm;
  quantityKgForm;
  formatForm;
  cutForm;

  // Lumps fish
  numberPieces = 0;
  maxNumberPieces = 0;
  minNumberPieces = 0;

  summary: SummaryOrder;
  differenceMinPrice: number;

  selectFormat;
  selectFormatName;
  approxWeight = 0;
  cutValid = false;

  minimum;
  maximum;
  useMinMax = false;
  letAddArticle = true;

  numeroFotos = 1;
  posicionesFotoSeleccionada = 0;

  newNavigationFn() {
    this.numberPieces = 0;
    this.initialVariablesValues();

    let productId;
    if (this.articleId != null) {
      if (this.articleId.includes('PRODUCT-')){
        productId = this.articleId.replace('PRODUCT-', '');
      }
    }

    if (productId) {
      this.productArticles = this.inputProductsArticles.find(e => e.id === productId);
      this.dropDownGauge = true;
      this.article = null;
    }


    this.initialValues();
    this.defaultSelectValues();
    this.calculatePriceUd();
    this.getNumberOfPhotos();
  }

  initialVariablesValues(){
    this.totalPrice = 0;
    this.navigationRoute = null;
    this.letAddArticle = true;
    this.selectKgs = this.selectKgsOriginal;
    this.selectUnits = this.selectUnitsOriginal;

    // Initial form values
    this.quantityUdForm = new FormGroup({
      quantity: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]+$'),
        Validators.min(1)]),
    });
    this.quantityKgForm = new FormGroup({
      quantity: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]+[.]{0,1}[0-9]{0,1}$'), this.minLimit()]),
    });

    this.formatForm = new FormGroup({
      format: new FormControl(null, [Validators.required]),
    });

    this.cutForm = new FormGroup({
      cut: new FormControl(null, [Validators.required]),
    });
  }

  initialValues () {
    this.useMinMax = false;
    this.minimum = null;
    this.maximum = null;

    if (this.article != null) {
      this.selectFormat = this.article.format;
      if (this.selectFormat === 1) {
        this.formatForm.controls['format'].setValue('1', {onlySelf: true});
        this.selectFormatName = 'Kilos';
      } else {
        this.selectFormatName = 'unidades';
      }

      if (this.article.minimum !== 0 && this.article.maximum !== 0) {
        this.useMinMax = true;
        this.minimum = this.article.minimum;
        this.maximum = this.article.maximum;
      }

      // Compruebo que no supere el limite permitido
      if (this.selectUnits != null || this.selectKgs != null) {
        if (this.selectUnits.length > 0 || this.selectKgs.length > 0){
          if (this.isUser){
            this.shoppingCarService.getPendingArticleQuantity(this.article.id).subscribe(
              (result: NumberInterface) => {
                this.setQuantity(result.value);
              }
            );
          } else {
            this.setQuantity(this.localStorage.getLocalStorageArticleQuantity(this.article.id));
          }
        }
      }
    } else {
      this.article = this.productArticles.articles[0];
      this.selectedArticle = this.productArticles.articles[0].gauge;
      this.selectFormat = this.article.format;

      if (this.selectFormat === 1) {
        this.formatForm.controls['format'].setValue(1, {onlySelf: true});
        this.selectFormatName = 'Kilos';
      } else {
        this.selectFormatName = 'unidades';
      }

      if (this.article.minimum !== 0 && this.article.maximum !== 0) {
        this.useMinMax = true;
        this.minimum = this.article.minimum;
        this.maximum = this.article.maximum;
      } else {
        this.totalPrice = this.article.priceIva * this.article.approxWeight;
      }

      this.totalPrice = this.functionsService.roundFn(this.companyConf, this.totalPrice, 2);
      this.approxWeight =  this.article.approxWeight;
    }
  }

  setQuantity(quantity){
    if (this.article.format === '1' && quantity >= this.selectKgs[this.selectKgs.length - 1]) {
      this.letAddArticle = false;
    } else if (this.article.format !== '1' && quantity >= this.selectUnits[this.selectUnits.length - 1]) {
      this.letAddArticle = false;
    } else if (this.article.format === '1') {
      let maxValue = this.selectKgs[this.selectKgs.length - 1] - quantity;
      let selectKgs = [];
      for (let i = 0.5; i <= maxValue; i = i + 0.5) {
        selectKgs.push(i);
      }
      this.selectKgs = selectKgs;
    } else if (this.article.format !== '1' ) {
      let maxValue = this.selectUnits[this.selectUnits.length - 1] - quantity;
      let selectUnits = [];
      for (let i = 1; i <= maxValue; i++) {
        selectUnits.push(i);
      }
      this.selectUnits = selectUnits;
    }
  }


  addQuantity(format: number) {
    let cantidad = 0;
    if (this.quantityUdForm.controls['quantity'].value == undefined) {
      cantidad = 1;
    } else {
      if (format == 1) { // Kilos
        const pos = this.selectKgs.findIndex(kg => kg > this.quantityUdForm.controls['quantity'].value);
        if (pos != -1) {
          cantidad = this.selectKgs[pos];
        }
      } else {
        const pos = this.selectUnits.findIndex(ud => ud > this.quantityUdForm.controls['quantity'].value);
        if (pos != -1) {
          cantidad = this.selectUnits[pos];
        }
      }
    }

    this.quantityUdForm.controls['quantity'].setValue(cantidad);
  }

  subtractQuantity(format: number) {
    let cantidad = 0;
    console.log(this.quantityUdForm.controls['quantity'].value);
    if (this.quantityUdForm.controls['quantity'].value == undefined) {
      cantidad = 1;
    } else {
      if (format == 1) { // Kilos
        let reverseArray = cloneDeep(this.selectKgs);
        reverseArray.reverse();
        const pos = reverseArray.findIndex(kg => kg < this.quantityUdForm.controls['quantity'].value);
        if (pos != -1) {
          cantidad = reverseArray[pos];
        } else {
          cantidad = 0;
        }
      } else {
        let reverseArray = cloneDeep(this.selectUnits);
        reverseArray.reverse();
        console.log(this.quantityUdForm.controls['quantity'].value);
        const pos = reverseArray.findIndex(ud => ud < this.quantityUdForm.controls['quantity'].value);
        if (pos != -1) {
          cantidad = reverseArray[pos];
        } else {
          cantidad = 0;
        }
      }
    }
    this.quantityUdForm.controls['quantity'].setValue(cantidad);



  }

  defaultSelectValues(){
    if (this.article.format === '1'){
      if (this.selectKgs.find(e => e == this.companyConf.limits.defaultKg)){
        this.quantityKgForm.controls['quantity'].setValue(this.companyConf.limits.defaultKg, {onlySelf: true});
        this.calculatePriceKg();
      }
    } else {
      this.quantityUdForm.controls['quantity'].setValue(1, {onlySelf: true});
    }
  }

  calculatePriceKg() {
    let totalP = 0;
    if (this.quantityKgForm.controls['quantity'].valid) {
      const kgs = this.quantityKgForm.controls['quantity'].value;
      totalP = parseFloat(kgs) * this.article.priceIva;


      if (this.useMinMax && this.indicateLumps) {
        this.numberPieces = 0;
        this.maxNumberPieces = Math.trunc(kgs / this.minimum);

        if (kgs % this.maximum !== 0) {
          this.minNumberPieces = Math.trunc(kgs / this.maximum) + 1;
        } else {
          this.minNumberPieces = kgs / this.maximum;
        }
        this.numberPieces = this.minNumberPieces;
      }
      this.totalPrice = this.functionsService.roundFn(this.companyConf, totalP, 2);
    } else {
      this.totalPrice = 0;
      this.numberPieces = 0;
      this.maxNumberPieces = 0;
      this.minNumberPieces = 0;
    }
  }

  calculatePriceUd() {
    let totalP = 0;
    if (this.quantityUdForm.controls['quantity'].valid) {
      totalP = parseFloat(this.quantityUdForm.controls['quantity'].value) * this.article.priceIva;
      this.totalPrice = parseFloat(((((totalP) + Number.EPSILON) * 100) / 100).toFixed(2));
    } else {
      this.totalPrice = 0;
    }
  }

  addArticleShoppingCar(content) {
    const arg: ArticleCookie = new ArticleCookie(this.article.id, 0, this.article.name, this.selectFormat,
      null, this.article.priceIva, this.article.nameClient, this.article.group, this.article.family,
      0, this.numberPieces, this.totalPrice, null, null, null);
    if (this.cutForm.controls['cut'].value != null) {
      const cut = this.productArticles.dropDownCuts.find(e => e.id === this.cutForm.controls['cut'].value);
      arg.cutType = this.cutForm.controls['cut'].value;
      arg.cutName = cut.name;
    }

    if (!this.dropDownGauge) {
      if (this.quantityUdForm.controls['quantity'].value != null && (this.article.format != '1' || this.companyConf.selectUniKg)) {
        arg.quantity = parseFloat(this.quantityUdForm.controls['quantity'].value);
      } else if (this.quantityUdForm.controls['quantity'].value != null) {
        let approxWeight = 1;
        if (this.article.approxWeight != null || this.article.approxWeight !== 0) {
          approxWeight = this.article.approxWeight;
        }
        arg.quantity = parseFloat(this.quantityUdForm.controls['quantity'].value) * approxWeight; // Lumps * aprox weiight
        arg.lumps = this.quantityUdForm.controls['quantity'].value;
      } else {
        arg.quantity = parseFloat(this.quantityKgForm.controls['quantity'].value);
      }
    } else {
      if (!this.useMinMax) {
        arg.quantity = this.approxWeight;
      } else {
        arg.quantity = parseFloat(this.quantityKgForm.controls['quantity'].value);
      }
    }

    if (!this.localStorage.addLocalStorageArticle(arg)) {
      this.shoppingCarService.addArticle(this.article.id, this.selectFormat, arg.quantity, arg.lumps,
        arg.cutType, false).subscribe(
        (data: SummaryOrder) => {
          this.getPrices(data);
          this.modalService.open(content, {backdropClass: 'light-grey-backdrop', centered: true});
        }
      );
    }

    if (this.authStorageService.getUser() == null) {
      const arts = this.localStorage.getLocalStorageArticles();
      let differenceMinPrice: SummaryOrder;
      let sumKg = 0;
      if (Array.isArray(arts)) {
        let sumPrice = 0;
        arts.forEach(e => {
          sumPrice = ((sumPrice * 10) + (e.totalIvaPrice * 10)) / 10;
          if (e.format === '1'){
            sumKg += e.quantity;
          }
        });
        sumPrice = parseFloat((Math.round((sumPrice + Number.EPSILON) * 100) / 100).toFixed(2));

        differenceMinPrice = new SummaryOrder(0, sumPrice, sumKg, arts.length.toString());
      } else {
        if (arts.format === '1'){
          sumKg = arts.quantity;
        }
        differenceMinPrice = new SummaryOrder(0, arts.totalIvaPrice, sumKg, '1');
      }

      this.totalTransport = 0;
      if (this.priceRoute.deliveryKg != 0 && this.priceRoute.deliveryPrice != 0 && this.companyConf.useRoutes) {
        this.totalTransport = Math.ceil(sumKg/this.priceRoute.deliveryKg) * this.priceRoute.deliveryPrice;
        if (this.priceRoute.fixedPrice > 0) {
          this.totalTransport += this.priceRoute.fixedPrice;
        }
        differenceMinPrice.totalPriceIva += this.totalTransport;
      } else if (this.priceRoute.minKg != 0 && this.priceRoute.deliveryPrice != 0) {
        this.totalTransport = this.priceRoute.deliveryPrice;
        differenceMinPrice.totalPriceIva += this.totalTransport;
      } else if (this.priceRoute.deliveryPrice !== 0 && (differenceMinPrice.totalPriceIva - this.totalTransport) < this.minPrice) {
        this.totalTransport = this.priceRoute.deliveryPrice;
        differenceMinPrice.totalPriceIva += this.totalTransport;
      }
      this.getPrices(differenceMinPrice);
      this.modalService.open(content, {backdropClass: 'light-grey-backdrop', centered: true});
    }

    // Compruebo que no supere el limite permitido
    if (this.selectUnits != null || this.selectKgs != null) {
      if (this.selectUnits.length > 0 || this.selectKgs.length > 0){
        if (this.isUser){
          this.shoppingCarService.getPendingArticleQuantity(this.article.id).subscribe(
            (result: NumberInterface) => {
              this.setQuantity(result.value);
            }
          );
        } else {
          this.setQuantity(this.localStorage.getLocalStorageArticleQuantity(this.article.id));
        }
      }
    }
  }

  getPrices(data: SummaryOrder) {
    data.totalPriceIva = (Math.round((data.totalPriceIva  + Number.EPSILON) * 100) / 100);
    this.summary = data;
    this.differenceMinPrice = (Math.round((this.minPrice - this.summary.totalPriceIva + Number.EPSILON) * 100) / 100);

    this.totalTransport = 0;
    if (this.priceRoute.deliveryKg != 0 && this.priceRoute.deliveryPrice != 0) {
      this.totalTransport = Math.ceil(data.sumKg / this.priceRoute.deliveryKg) * this.priceRoute.deliveryPrice;
      if (this.priceRoute.fixedPrice > 0) {
        this.totalTransport += this.priceRoute.fixedPrice;
      }
      this.summary.totalPriceIva += this.totalTransport;
    } else if (this.priceRoute.minKg != 0 && this.priceRoute.deliveryPrice != 0) {
      this.totalTransport = this.priceRoute.deliveryPrice;
      this.summary.totalPriceIva += this.totalTransport;
    } else if (this.priceRoute.deliveryPrice !== 0 && (this.summary.totalPriceIva - this.totalTransport) < this.minPrice) {
      this.totalTransport = this.priceRoute.deliveryPrice;
      this.summary.totalPriceIva += this.totalTransport;
    }

    // Envío el número de articulos al componente principal
    this.numberArticles.emit(parseInt(this.summary.numArticles));
    this.closeWindow.emit(true);
  }

  openModalArticle(content) {
    this.addArticleShoppingCar(content);
  }

  openModalTag(content) {
    this.modalService.open(content, {backdropClass: 'light-grey-backdrop', centered: true});
  }

  closeModal(modal) {
    if (this.quantityUdForm.controls['quantity'].value != null) {
      this.quantityUdForm = new FormGroup({
        quantity: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]+$'),
          Validators.min(1)]),
      });
    } else {
      this.quantityKgForm = new FormGroup({
        quantity: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]+$'),
          Validators.min(100)]),
      });
    }
    modal.close();
  }

  cutValidFn() {
    this.cutValid = this.cutForm.controls['cut'].value != null;
  }

  // VALIDATORS FUNCTIONS
  minLimit(): ValidatorFn {
    return (c: AbstractControl): {[key: string]: any} | null => {
      if (this.quantityKgForm != null) {
        if (!isNaN(c.value)) {
          if (!this.useMinMax) {
            return null;
          }
          if (c.value >= this.minimum) {
            return null;
          }
        }
      }
      return {errorMinLimits: {value: c.value}};
    };
  }

  addValue() {
    if (this.numberPieces < this.maxNumberPieces){
      this.numberPieces++;
    }
  }

  subtractValue() {
    if (this.numberPieces > this.minNumberPieces && this.numberPieces > 1){
      this.numberPieces--;
    }
  }

  scrollTop(){
    window.scroll(0,0);
  }

  openModal(modal) {
    this.modalService.open(modal, {backdropClass: 'light-grey-backdrop', centered: true});
  }

  navigateToGroup(article) {
    if (article.superGroup != null && article.superGroup !== '') {
      this.redirectToGrupo(article.groupName, article.superGroup,  article.group);
    } else {
      this.redirectToGrupo(article.groupName, 'none',  article.group);

    }
  }

  redirectToGrupo(nombre: string, superGrupo: string, grupo: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate([
        '/compra/' + nombre.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('')
      ], {
        queryParams: { superGrupo: superGrupo, grupo: grupo }
      }));
  }

  urlImg(url: string): string {
    if (this.numeroFotos > 1) {
      if (this.posicionesFotoSeleccionada != 0) {
        return this.unirCadenasDespuesDelUltimoPunto(url, '_' + this.posicionesFotoSeleccionada);
      }

      return this.replaceLastUnderscoreWithEmpty(url);
    }

    return this.replaceLastUnderscoreWithEmpty(url);
  }

  replaceLastUnderscoreWithEmpty(inputString: string): string {
    const regex = /_(\d+)(?=[^_]*$)/;
    return inputString.replace(regex, '');
  }

  async getNumberOfPhotos() { //  imgUrl: string
    if (this.article != undefined && this.companyConf.companyException == 'ENJA') {
      if (this.article.articleImgUrl != null) {
        let existe = true;
        let int = 0;
        while (existe) {
          if (int == 0) {
            existe = await this.checkIfResourceExists('assets/fotos/articulos/' + this.article.articleImgUrl);
            // this.article.articleImgUrl = this.urlImg(this.article.articleImgUrl);
          } else {
            existe = false;
            let img = cloneDeep(this.article.articleImgUrl);
            const resultado = this.unirCadenasDespuesDelUltimoPunto(img, '_' + int);
            existe = await this.checkIfResourceExists('assets/fotos/articulos/' + resultado);
            if (existe) {
              this.numeroFotos += 1;
            }
          }

          int++;
        }
      }

    }
  }

  unirCadenasDespuesDelUltimoPunto(cadena1: string, cadena2: string): string {
    const ultimoPuntoIndex = cadena1.lastIndexOf('.');

    if (ultimoPuntoIndex !== -1) {
      const parteAntesDelUltimoPunto = cadena1.substring(0, ultimoPuntoIndex);

      const parteDespuesDelUltimoPunto = cadena1.substring(ultimoPuntoIndex);

      const resultado = parteAntesDelUltimoPunto  + cadena2 + parteDespuesDelUltimoPunto;

      return resultado;
    } else {
      return cadena1 + cadena2;
    }
  }

  arrayFromNumber(limit: number): number[] {
    return Array.from({ length: limit }, (_, index) => index);
  }

  async checkIfResourceExists(url: string): Promise<boolean> {
    try {
      await this.http.head(url).toPromise();
      return true;
    } catch (error) {
      return false;
    }
  }
}
