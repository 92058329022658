<div style="min-height: 100vh">
  <div class="container" *ngIf="datosLegalesEmpresa">
  <h3 class="pt-4 mb-3"><a routerLink="/"><i class="fas fa-arrow-left"></i> Volver</a></h3>
  <h1><b>Aviso legal y condiciones generales de uso del sitio web</b></h1>

  <h2><i><u>Identificación y Titularidad</u></i></h2>
  <p>En cumplimiento del artículo 10 de la Ley 34 / 2002, de 11 de julio,
  de Servicios de la Sociedad de la Información
  y Comercio Electrónico, el Titular expone sus datos identificativos.</p>
  <div><b>Nombre:</b> {{datosLegalesEmpresa.nameR}}</div>
  <div><b>Registro mercantil:</b> {{datosLegalesEmpresa.name}}</div>
  <div><b>CIF:</b> {{datosLegalesEmpresa.cif}}</div>
  <div><b>Domicilio:</b> {{datosLegalesEmpresa.direction}}</div>
  <div><b>Correo electrónico:</b> {{datosLegalesEmpresa.email}}</div>
  <div><b>Actividad:</b> {{datosLegalesEmpresa.activity}} </div>
  <div><b>Especialidad:</b> {{datosLegalesEmpresa.specialty}} </div><br/>

  <h2><i><u>Condiciones de uso</u></i></h2>
  <p>La utilización del sitio Web le otorga la condición de Usuario,
  e implica la aceptación completa de todas las cláusulas y condiciones de uso
  incluidas en las páginas:</p>
  <ul>
  <li>Aviso Legal</li>
  <li>Política de Privacidad</li>
  </ul>
  <p>Si no estuviera conforme con todas y cada una de estas cláusulas y
  condiciones absténgase de utilizar este sitio Web.</p>
  <p>El acceso a este sitio Web no supone, en modo alguno,
  el inicio de una relación comercial con {{datosLegalesEmpresa.nameR}}.</p>
  <p>A través de este sitio Web, {{datosLegalesEmpresa.nameR}} le facilita el acceso y
  la utilización de diversos contenidos que {{datosLegalesEmpresa.nameR}} ha publicado por medio de Internet.</p>
  <p>A tal efecto, usted está obligado y comprometido a NO utilizar cualquiera de los contenidos del
  sitio Web con fines o efectos ilícitos, prohibidos en este Aviso Legal o por la legislación vigente,
  lesivos de los derechos e intereses de terceros,
  o que de cualquier forma puedan dañar, inutilizar, sobrecargar,
  deteriorar o impedir la normal utilización de los contenidos,
  los equipos informáticos o los documentos,
  archivos y toda clase de contenidos almacenados en cualquier equipo informático propios o
  contratados por {{datosLegalesEmpresa.nameR}}, de otros usuarios o de cualquier usuario de Internet.</p>

  <h2><i><u>Medidas de seguridad</u></i></h2>
  <p> Los datos personales que facilite a {{datosLegalesEmpresa.nameR}}
  pueden ser almacenados en bases de datos,
  cuya titularidad corresponde en exclusiva a el {{datosLegalesEmpresa.nameR}},
  que asume todas las medidas de índole técnica,
  organizativa y de seguridad que garantizan la confidencialidad,
  integridad y calidad de la información contenida en las mismas de acuerdo con lo establecido
  en la normativa vigente en protección de datos.
  </p>
  <p>No obstante, debe ser consciente de que las medidas de seguridad de los sistemas informáticos
  en Internet no son enteramente fiables y que, por tanto {{datosLegalesEmpresa.nameR}} no puede garantizar
  la inexistencia de virus u otros elementos que puedan producir alteraciones en los
  sistemas informáticos (software y hardware) del Usuario o en sus documentos electrónicos y
  ficheros contenidos en los mismos aunque el Titular pone todos los medios necesarios y
  toma las medidas de seguridad oportunas para evitar la presencia de estos elementos dañinos.</p>

  <h2><i><u>Datos personales</u></i></h2>
  <p>Usted puede consultar toda la información relativa al tratamiento de datos personales que recoge el
  {{datosLegalesEmpresa.nameR}} en la página de la Política de Privacidad.</p>

  <h2><i><u>¿Cuánto tiempo conservamos tu información personal?</u></i></h2>
  <p>Conservamos tu información personal a fin de que puedas utilizar los servicios de {{datosLegalesEmpresa.nameR}} de
  forma continuada y durante el tiempo necesario para cumplir los fines descritos en este Aviso de Privacidad,
  según resulte exigible conforme a la legislación (por ejemplo, con fines fiscales y contables),
  o de cualquier otro modo que te hayamos comunicado.</p>

  <h2><i><u>Enlaces de interés a otros sitios Web</u></i></h2>
  <p>
  El Sitio Web puede contener hipervínculos que permitan al Usuario acceder a páginas Web de terceros.<br/>
  {{datosLegalesEmpresa.nameR}} no asume ninguna responsabilidad por el contenido,
  informaciones o servicios que pudieran aparecer en dichos sitios,
  que se entenderán ofrecidos exclusivamente con carácter informativo por parte de {{datosLegalesEmpresa.nameR}},
  y que en ningún caso implican relación, aceptación o respaldo alguno entre {{datosLegalesEmpresa.nameR}} y
  las personas o entidades titulares de tales contenidos o titulares de los sitios donde se encuentren.
  </p>

  <h2><i><u>Contenidos</u></i></h2>
  <p>
  Queda prohibido transmitir o enviar a través del sitio Web cualquier contenido ilegal o ilícito,
  virus informáticos, o mensajes que, en general,
  afecten o violen derechos de {{datosLegalesEmpresa.nameR}} o de terceros.
  </p>
  <p>
  {{datosLegalesEmpresa.nameR}} se reserva el derecho a modificar, suspender,
  cancelar o restringir el contenido del Sitio Web,
  los vínculos o la información obtenida a través del sitio Web, sin necesidad de previo aviso.
  </p>
  <p>
  {{datosLegalesEmpresa.nameR}} no es responsable de los daños y
  perjuicios que pudieran derivarse de la utilización de la información del sitio Web.
  </p>

  <h2><i><u>Propiedad intelectual</u></i></h2>
  <p>
  Todos los derechos están reservados.
  </p>
  <p>
  Todo acceso a este sitio Web está sujeto a las siguientes condiciones:
  la reproducción, almacenaje permanente y la difusión de los contenidos o cualquier otro
  uso que tenga finalidad pública o comercial queda expresamente prohibida sin el
  consentimiento previo expreso y por escrito de {{datosLegalesEmpresa.nameR}}.
  </p>

  <h2><i><u>Derecho de exclusión</u></i></h2>
  <p>
  La información y servicios incluidos o disponibles a través de este sitio Web
  pueden incluir incorrecciones o errores tipográficos.
      De forma periódica {{datosLegalesEmpresa.nameR}} incorpora mejoras y/o cambios a la información contenida
      y/o los Servicios que puede introducir en cualquier momento.
    </p>
    <p>
      {{datosLegalesEmpresa.nameR}} no declara ni garantiza que los servicios o contenidos sean interrumpidos
      o que estén libres de errores, que los defectos sean corregidos,
      o que el servicio o el servidor que lo pone a disposición estén libres de virus
      u otros componentes nocivos sin perjuicio de que {{datosLegalesEmpresa.nameR}} realiza todos los esfuerzos
      en evitar este tipo de incidentes.
    </p>
    <p>
      {{datosLegalesEmpresa.nameR}} declina cualquier responsabilidad en caso de que existan interrupciones
      o un mal funcionamiento de los Servicios o contenidos ofrecidos en Internet,
      cualquiera que sea su causa. Asimismo, {{datosLegalesEmpresa.nameR}} no se hace responsable por caídas de la red,
      pérdidas de negocio a consecuencia de dichas caídas,
      suspensiones temporales de fluido eléctrico o cualquier otro tipo de daño indirecto que te
      pueda ser causado por causas ajenas a {{datosLegalesEmpresa.nameR}}.
    </p>
    <p>
      Antes de tomar decisiones y/o acciones con base a la información incluida en el sitio Web,
      {{datosLegalesEmpresa.nameR}} le recomienda comprobar y contrastar la información recibida con otras fuentes.
    </p>

    <h2><i><u>Legislación aplicable y jurisdicción</u></i></h2>
    <p>Este Aviso Legal se rige íntegramente por la legislación española.</p>
    <p>
      Siempre que no haya una norma que obligue a otra cosa,
      para cuantas cuestiones se susciten sobre la interpretación,
      aplicación y cumplimiento de este Aviso Legal,
      así como de las reclamaciones que puedan derivarse de su uso,
      las partes acuerdan someterse a los Jueces y Tribunales de la provincia de {{province}},
      con renuncia expresa de cualquier otra jurisdicción que pudiera corresponderles.
    </p>

    <h2><i><u>Contacto</u></i></h2>
    <p>En caso de que usted tenga cualquier duda acerca de estas Condiciones legales
      o quiera realizar cualquier comentario sobre este sitio Web, puede enviar un mensaje
      de correo electrónico a la dirección {{datosLegalesEmpresa.email}}.
    </p>
  </div>
</div>


