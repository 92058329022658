import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UserInfo} from '../../../interfaces/user-info';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../../services/auth/auth.service';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {CompanyConf} from '../../../interfaces/company-conf';
import {AuthStorageService} from '../../../services/auth/auth-storage.service';
import {Router} from '@angular/router';
import {ModalManager} from 'ngb-modal';
import {WebEstaticaService} from '../../../services/web-estatica/web-estatica.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  @Input() user: UserInfo;
  @Input() companyConf: CompanyConf;
  passwordForm;
  incorrectPassword;
  errorPhone;
  errorMail;
  editProfile = false;
  notSame = false;

  hide0 = true;
  hide1 = true;
  hide2 = true;

  userForm = new FormGroup({
  phoneNumber: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{9}$')]),
  phoneNumber2: new FormControl(null, [Validators.pattern('^[0-9]{9}$')]),
  email: new FormControl(null, [Validators.email]),
  observations: new FormControl(null),
  });

  @ViewChild('EliminarClienteModal') eliminarClienteModal: any;
  private modalRef: any;
  encuesta: any | undefined;
  constructor(private modalService: NgbModal, private authService: AuthService,
      private authStorageService: AuthStorageService, private router: Router,
      private modalService1: ModalManager, private webEstaticaService: WebEstaticaService) { }

  ngOnInit(): void {
  this.initValuesProfile();


  this.webEstaticaService.getEncuestaJSON().subscribe(data => {
  this.encuesta = data;
  });
  }

  initValuesProfile() {
  this.editProfile = false;
  this.userForm.controls['phoneNumber'].setValue(this.user.phone, {onlySelf: true});
  this.userForm.controls['phoneNumber2'].setValue(this.user.phone2, {onlySelf: true});
  this.userForm.controls['email'].setValue(this.user.email, {onlySelf: true});
  this.userForm.controls['observations'].setValue(this.user.observations, {onlySelf: true});
  }

  setProfileInfo() {
  this.authService.editProfile(this.userForm.controls['phoneNumber'].value, this.userForm.controls['phoneNumber2'].value,
  this.userForm.controls['email'].value, this.userForm.controls['observations'].value).subscribe(
  () => {
    this.user.phone = this.userForm.controls['phoneNumber'].value;
    this.user.phone2 = this.userForm.controls['phoneNumber2'].value;
    this.user.email = this.userForm.controls['email'].value;
    this.user.observations = this.userForm.controls['observations'].value;
    this.editProfile = false;
    }, error => {
    if (error.error.error === 'Este teléfono ya esta siendo utilizado por otro usuario.') {
    this.errorPhone = 'Este teléfono ya esta siendo utilizado por otro usuario.';
    } else if (error.error.error === 'Este correo electrónico ya esta siendo utilizado por otro usuario.') {
    this.errorMail = 'Este correo electrónico ya esta siendo utilizado por otro usuario.';
    }
  }
  );
  }

  openModalPassword(content) {
  this.incorrectPassword = null;
  this.passwordForm = new FormGroup({
  oldPassword: new FormControl('', [Validators.required, Validators.minLength(4),
    Validators.maxLength(10)]),
  password: new FormControl('', [Validators.required, Validators.minLength(4),
    Validators.maxLength(10), this.notSamePassword(), this.validLetters()]),
  password_confirmation: new FormControl('', [Validators.required, this.samePassword()]),
  });
  this.modalService.open(content, {backdropClass: 'light-grey-backdrop', centered: true});
  }


  setPasswordFn(modal) {
  this.notSame = false;
  if (this.passwordForm.value.password !== this.passwordForm.value.password_confirmation) {
  this.notSame = true;
  } else {
  this.authService.setPassword(this.passwordForm.value.oldPassword, this.passwordForm.value.password).subscribe(
    () => {
    modal.close();
    },
    error => {
    if (error.error.error === 'La contraseña no es correcta') {
    this.incorrectPassword = 'La contraseña no es correcta';
    }
    }
  );
  }
  }

  logOut() {
  this.authStorageService.logOut();
  this.cerrarModal();
  this.router.navigateByUrl('/iniciarSesion', { state: { newPassword: false } });
  }

  // ----------------------------Validation functions ------------------------------
  samePassword(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
  if (this.passwordForm != null) {
    if (control.value === this.passwordForm.value.password) {
    return null;
    }
  }
  return {'samePasswordIncorrect': {value: control.value}};
  };
  }

  notSamePassword(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
  if (this.passwordForm != null) {
    if (control.value === this.passwordForm.value.oldPassword) {
    return {'notSamePasswordIncorrect': {value: control.value}};
    }
  }
  return null;
  };
  }

  abrirModalEliminarCuenta() {
  this.modalRef = this.modalService1.open(this.eliminarClienteModal,
  {
    size: "md",
    hideCloseButton: false,
    centered: true,
    backdrop: true,
    animation: true,
    keyboard: false,
    closeOnOutsideClick: false,
    backdropClass: "modal-backdrop"
  });
  }

  validLetters(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
  if (this.passwordForm != null) {
    if (!(control.value.includes('\''))) {
    return null;
    }
  }
  return {validLettersIncorrect: {value: control.value}};
  };
  }

  darDeBajaCliente() {
  this.authService.bloquearCliente().subscribe();
  this.logOut();
  }

  cerrarModal(){
  this.modalRef.close();
  }
}
