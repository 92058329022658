<nav class="max d-flex justify-content-center align-items-center mt-2">
  <div class="center" style="min-height: 35px">
  <a class="products btn" (click)="redireccionarBuscar()">BUSCAR</a>
  <a class="products btn" routerLink="/compra/ofertas" *ngIf="showOffers">OFERTAS</a>

  <div class="d-flex">
  <div class="mb-0 ml-0 ml-0 mr-0" id="container-list" *ngFor="let superGroup of superGroups">
    <div *ngIf="superGroup.id !== '' && superGroup.id != 'none'; else elseGroup"
     class="ml-0 mr-0 products btn btn-default"
     id="{{superGroup.id}}" (click)="getArticlesSuperGroup(superGroup)">
    <div *ngIf="superGroup.groups.length !== 1; else elseNameGroup">
    <div class="dropdown m-0">
      <button class="bg-transparent border-0" (click)="getArticlesSuperGroupAll(superGroup)">
      {{superGroup.name}}<i class="ml-1 fas fa-caret-down"></i>
      </button>
      <div class="dropdown-content container-groups">
      <div *ngFor="let group of superGroup.groups" class="groups">
      <div class="btn btn-default group"
         (click)="getArticlesSuperGroupGroup(superGroup, group)">
        {{group.name}}
      </div>
      </div>
      </div>
    </div>
    </div>

    <ng-template #elseNameGroup>
    {{superGroup.name}}
    </ng-template>
    </div>

    <ng-template #elseGroup>
    <div class="d-flex">
    <div *ngFor="let group of superGroup.groups">
      <div class="ml-0  mr-0 products btn btn-default" id="{{group.id}}"
       (click)="getArticles(group)" *ngIf="group.id != '' && group.id != 'of'">
      {{group.name}}
      </div>
    </div>
    </div>
    </ng-template>
  </div>
  </div>
  </div>
</nav>
