import {Component, Input, OnInit} from '@angular/core';
import {UserInfo} from '../../../interfaces/user-info';
import {AuthService} from '../../../services/auth/auth.service';
import {Direction} from '../../../interfaces/direction';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-directions',
  templateUrl: './directions.component.html',
  styleUrls: ['./directions.component.scss']
})
export class DirectionsComponent implements OnInit {
  @Input() user: UserInfo;

  directions: Array<Direction>;
  directionPostal: Direction;
  selectedDirection: Direction;

  constructor(private authService: AuthService, private modalService: NgbModal) { }

  ngOnInit(): void {
  this.getDirections();

  this.authService.getUser().subscribe(
  (data: UserInfo) => {
    this.directionPostal = new Direction(data.country, data.countryName, data.province, data.provinceName, data.city,
    data.cityName, data.direction, data.phone, data.deliveryDays, data.isInRoute);
  }
  );
  }

  getDirections() {
  this.authService.getUserDirections().subscribe(
  (data: Array<Direction>) => {
    this.directions = data;
  }
  );
  }

  open(content, direction) {
  this.selectedDirection = direction;
  this.modalService.open(content, {backdropClass: 'light-grey-backdrop', centered: true});
  }

  deleteUserDirection(modal) {
  this.directions = this.directions.filter(e => !(e.direction === this.selectedDirection.direction &&
  e.country === this.selectedDirection.country && e.province === this.selectedDirection.province &&
  e.city === this.selectedDirection.city));
  modal.close();

  this.authService.deleteUserDirection(this.selectedDirection.country, this.selectedDirection.province,
  this.selectedDirection.city, this.selectedDirection.direction).subscribe();
  }
}
